/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useState, useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import ApiWrapper from '../../ApiWrapper';
import { useDebounce } from '../useDebounce';

interface IPaginatedData{
  items: Array<any>;
  status: string;
  totalCount: number;
  summary?: any;
  refetch: () => void;
}

interface IPaginatedDataProps{
  endpoint: string;
  page: number;
  rowsPerPage: number;
  filters: {[key:string]:string} | undefined;
  sortDirection: 'asc' | 'desc';
  sortColumn: string;
}

const usePaginatedData = ({
  endpoint = '',
  page = 0,
  rowsPerPage = 10,
  filters = {},
  sortDirection = 'asc',
  sortColumn = ''
}: IPaginatedDataProps = {
  endpoint: '',
  page: 0,
  rowsPerPage: 10,
  filters: {},
  sortDirection: 'asc',
  sortColumn: ''
}) : IPaginatedData => {

  const [items, setItems] = useState([]);
  const [status, setStatus] = useState('init');
  const [totalCount, setTotalCount] = useState(0);
  const [summary, setSummary ]= useState(undefined);
  const [safeFilters, setSafeFilters] = useState<{[key:string]:string}>(filters ? { ...filters } : {});

  // Workaround to callback below dependency changing infinitely due to array reference
  useEffect(() => {
    if (filters && JSON.stringify(filters) !== JSON.stringify(safeFilters)) {
      setSafeFilters({ ...filters });
    }
  }, [filters, safeFilters]);

  const fetchData = useCallback( async () => {
    if (!endpoint) {
      return;
    }
    try {
      const params = {
        offset: rowsPerPage * page,
        rowsPerPage,
        ...safeFilters,
        sortDirection,
        sortColumn
      };
      setStatus('loading');
      const response : AxiosResponse = await ApiWrapper({
        url: endpoint,
        method: 'GET',
        params
      });
      setStatus('success');
      setItems(response.data.items);
      setTotalCount(response.data.totalCount);
      if (response.data.summary) {
        setSummary(response.data.summary);
      }
    } catch (error) {
      setStatus('error');
      setItems([]);
      setTotalCount(0);
      setSummary(undefined);
      console.error(error);
    }
  }, [page, sortDirection, sortColumn, safeFilters, rowsPerPage, endpoint]);

  const debouncedFetchData = useDebounce(() => fetchData, 400);

  useEffect(() => {
    debouncedFetchData().catch( (e) => console.error('Could not fetch', e) );
  }, [debouncedFetchData]);

  return {
    items,
    status,
    totalCount,
    summary,
    refetch: debouncedFetchData
  };
};

export default usePaginatedData;
