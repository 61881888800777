import React, { ReactElement } from 'react';
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import {
  IOverviewCycleHistory
} from '../../interfaces/overview';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 250
  },
  chart: {
    flexGrow: 1,
    height: '100%',
    minHeight: 250
  }
}));

const CycleHistory = ( { data } : {data: IOverviewCycleHistory }) : ReactElement => {
  const classes = useStyles();
  const mappedData = data.history ? data.history.map( (datum) => ( { name: datum.month, value: datum.cycles } )) : [];
  return (
    <Card square className={classes.root}>
      <ResponsiveContainer className={classes.chart}>
        <LineChart data={mappedData} margin={{ top: 15, right: 20, left: -15, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="value"
            name="Cycles"
            stroke="#173ca6"
            strokeWidth={2}
            dot={{ fill: '#173ca6' }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Card>
  );
};

export { CycleHistory };
