import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  overFlowRoot: {
    flexGrow: 1,
    width: '100%'
  },
  card: {
    display: 'flex',
    flexGrow: 1
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flex: '1 0 auto'
  },
  overview: {
    backgroundColor: 'rgb(233,234,236)',
    height: '100%',
    width: '100%',
    flexGrow: 1
  },
  block: {
    height: '300px',
    width: '300px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  sectionTitle: {
    flexGrow: 1,
    maxHeight: '50px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    background: '{ theme.backgroundColor }'
  },
  titleBar: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px'
  },
  sectionTitleIcon: {
    paddingRight: '1em',
    margin: '2px'
  },
  topRow: {
    marginTop: '1em'
  },
  middleRow: {
    marginTop: '1em'
  },
  bottomRow: {
    marginTop: '5px',
    marginBottom: '10px'
  },
  alertHistoryChart: {
    display: 'flex'
  }
}));
