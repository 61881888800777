import React, { ReactElement } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import { IEnhancedTableHeadProps } from '../../interfaces/tables';


const useStyles = makeStyles(() => ({
  column: {
    fontWeight: 'bold'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function EnhancedTableHead({
  columns, order, orderBy, className, hideControlColumn
}: IEnhancedTableHeadProps): ReactElement {
  const classes = useStyles();

  return (
    <TableHead className={className}>
      <TableRow>
        {!hideControlColumn && (
          <TableCell />
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            variant={'head'}
            padding={'default'}
            className={classes.column}
            size={'small'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  // classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  numSelected: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
