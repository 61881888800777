import { createMuiTheme } from '@material-ui/core/styles';

const primaryColor = '#1A222A';
const secondaryColor = '#013CA6'; // qstraint blue
// We do not want to keep the below colors
const errorColor = '#ED5000';
const warningColor = '#FFBC3D';
const infoColor = '#1188CE';
const successColor = '#00AC3E';
const backgroundColor = '#ecedef';
const secondaryDark = '#10308b'; // active select highlight

// Theme definition
const QstraintTheme = createMuiTheme({
  palette: {
    primary: { main: primaryColor, dark: backgroundColor },
    secondary: { main: secondaryColor, dark: secondaryDark },
    error: { main: errorColor },
    warning: { main: warningColor },
    info: { main: infoColor },
    success: { main: successColor },
    background: { default: backgroundColor }
  },
  typography: {
    fontFamily: 'Lato',
    fontSize: 14,
    h5: {
      fontSize: '14pt',
      fontWeight: 'bold'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          padding: '0px',
          margin: '0px'
        },
        body: {
          padding: '0px',
          margin: '0px',
          background: backgroundColor
        }
      }
    },
    MuiTableCell: {
      paddingCheckbox: {
        padding: 'unset'
      }
    },
    MuiTablePagination: {
      root: {
        alignSelf: 'center'
      }
    },
    MuiButton: {
      root: {
        fontFamily: 'Lato',
        textTransform: 'unset',
        margin: 'unset',
        padding: 'unset'
      }
    },
    MuiAppBar: {
      root: {
        margin: '0px'
      }
    },
    MuiContainer: {
      root: {
        padding: '0px',
        margin: '0px',
        background: 'white'
      }
    },
    MuiGrid: {
      root: {
        background: backgroundColor,
        padding: '0px'
      },
      container: {
        background: backgroundColor
      },
      item: {
        background: backgroundColor,
        padding: '0px',
        margin: '0px'
      }
    }
  }
});

export { QstraintTheme };
