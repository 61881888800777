import React, { Dispatch, FunctionComponent, useReducer } from 'react';
import PropTypes from 'prop-types';
import userDataReducer from '../reducers/user/userDataReducer';
import { TUserData } from '../reducers/user/types';

type Action = {
  type: string;
  payload?: any;
};

const storedEmail = sessionStorage.getItem('email');
const firstLogin = sessionStorage.getItem('firstLogin');
const firstName = sessionStorage.getItem('firstName');
const lastName = sessionStorage.getItem('lastName');
const isAdmin = sessionStorage.getItem('isAdmin');
const accessRules = sessionStorage.getItem('accessRules');

const initialState = {
  email: storedEmail !== '' ? storedEmail : '',
  isFirstLogin: firstLogin && firstLogin === 'true',
  firstName: firstName !== '' ? firstName : '',
  lastName: lastName !== '' ? lastName : '',
  isAdmin: isAdmin !== '' ? (isAdmin === 'true') : false,
  accessRules: accessRules && accessRules !== '' ? JSON.parse(accessRules) : { static: [], dynamic: [] }
};

export type TUserContext = [TUserData, Dispatch<Action>];

const UserContext = React.createContext<TUserContext | null>(null);

const UserDataProvider: FunctionComponent = ({ children }) => {
  const userData: TUserContext = useReducer(userDataReducer, initialState);
  return <UserContext.Provider value={userData}>{children}</UserContext.Provider>;
};

export { UserDataProvider, UserContext };

UserDataProvider.propTypes = {
  children: PropTypes.node
};

UserDataProvider.defaultProps = {
  children: React.Children
};
