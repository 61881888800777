import { updateEmail, setFirstLogin, setIsAdmin, updateName, setAccessRules, logout, TUserData } from './types';

type Action = {
  type: string;
  payload?: any;
};

export default function userDataReducer(state: TUserData, { payload, type }: Action) : TUserData {
  const { email } = payload;

  switch (type) {
    case logout:
      sessionStorage.removeItem('email');
      sessionStorage.removeItem('firstLogin');
      sessionStorage.removeItem('firstName');
      sessionStorage.removeItem('lastName');
      sessionStorage.removeItem('isAdmin');
      sessionStorage.removeItem('accessRules');
      sessionStorage.removeItem('JWT_TOKEN');
      sessionStorage.removeItem('JWT_EXPIRE');
      return {
        ...state,
        isAdmin: false
      };
    case updateEmail:
      sessionStorage.setItem('email', payload.email);

      return {
        ...state,
        email
      };
    case setFirstLogin:
      sessionStorage.setItem('firstLogin', payload.isFirstLogin);
      return {
        ...state,
        isFirstLogin: payload.isFirstLogin
      };
    case updateName:
      sessionStorage.setItem('firstName', payload.firstName);
      sessionStorage.setItem('lastName', payload.lastName);
      return {
        ...state,
        firstName: payload.firstName,
        lastName: payload.lastName
      };
    case setIsAdmin:
      sessionStorage.setItem('isAdmin', payload.isAdmin);
      return {
        ...state,
        isAdmin: payload.isAdmin
      };
    case setAccessRules:
      sessionStorage.setItem('accessRules', JSON.stringify(payload.accessRules));
      return {
        ...state,
        accessRules: payload.accessRules
      };
    default:
      throw new Error(`Unknown action ${type} in userDataReducer.js`);
  }
}
