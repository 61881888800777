/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, ReactElement } from 'react';
import { Button, FormControl, Grid, TextField, Backdrop, CircularProgress  } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { useStyles } from '../sectionStyle';
import { useForm } from 'react-hook-form';
import { IRequestStatus } from '../../interfaces/util';
import ApiWrapper from '../../ApiWrapper';
import { useHistory } from 'react-router-dom';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { propertiesSummary } from '../../endpoints/properties/properties';
import { regionsSummary } from '../../endpoints/regions/regions';
import { addUser as addUserEndpoint } from '../../endpoints/users/';
import cn from 'classnames';

interface IRequestStatusWithDuplicate extends IRequestStatus{
  duplicate: boolean;
}

type TUserFormData = {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  experienceGroupId: string;
}

const AddUser = () : React.ReactElement => {
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<IRequestStatusWithDuplicate>({ loading: false, success: false, error: false, duplicate: false });
  const [propertyRequestStatus, setPropertyRequestStatus] = useState('init');
  const [regionRequestStatus, setRegionRequestStatus] = useState('init');

  const [modalDismissed, setModalDismissed] = useState(true);
  const history = useHistory();
  const [role, setRole] = useState('');

  const dismissModal = () => {
    setModalDismissed(true);
  };

  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );
  const { register, handleSubmit, errors } = useForm<TUserFormData>();

  const onSubmit = async (data: TUserFormData): Promise<void> => {

    setModalDismissed(false);

    try {
      setRequestStatus({ loading: true, success: false, error: false, duplicate: false });
      await ApiWrapper({
        url: addUserEndpoint,
        method: 'POST',
        data: data
      });
      setRequestStatus({ loading: false, success: true, error: false, duplicate: false });

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => () => {
        history.push('/users');
        return null;
      } );
    } catch (error) {
      if (error.response.status === 409) {
        setRequestStatus({ loading: false, success: false, error: false, duplicate: true });
      } else {
        setRequestStatus({ loading: false, success: false, error: true, duplicate: false });
      }
      console.error(error);
    }
  };

  const hideExperienceGroupInput = role.length === 0 || ['admin', 'service', 'production'].indexOf(role) !== -1;
  const groupLabel = role === 'customer' ? 'Property' : 'Region';
  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="add-user-backdrop" onClick={modalAction} open={!modalDismissed} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {requestStatus.duplicate && (<span className={classes.loadingError}>A user with this email address already exists.</span>)}
        {requestStatus.error && (<span className={classes.loadingError}>Could not add user. Please refresh and try again.</span>)}
        {requestStatus.success && (<span className={classes.loadingError}>User added successfully.</span>)}
      </Backdrop>
      <PageDataConfig title="Add User" />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
        <FormControl className={classes.formControl}>
          <TextField
            id="role"
            label="Role"
            name="role"
            select
            SelectProps={{
              native: true
            }}
            inputRef={register({ required: true })}
            error={Boolean(errors.role)}
            helperText={errors.role ? 'Role must be selected' : ''}
            className={classes.formInput}
            onChange={
              (e) => {
                setRole( e.target.value );
              }
            }
          >
            <option value="">Role</option>
            <option value="admin">Admin</option>
            <option value="service">Service</option>
            <option value="production">Production</option>
            <option value="sales">Sales</option>
            <option value="customer">Customer</option>
          </TextField>
        </FormControl>
        <FormControl className={cn({
          [classes.formControl]: true,
          [classes.hiddenInput]: hideExperienceGroupInput
        })}>
          <TextField
            id="experienceGroupId"
            data-testid="property-options"
            label={groupLabel}
            name="experienceGroupId"
            select
            SelectProps={{
              native: true
            }}
            style={hideExperienceGroupInput ? { display: 'none' } : undefined}
            inputRef={register({ required: !hideExperienceGroupInput })}
            error={Boolean(errors.experienceGroupId)}
            helperText={errors.experienceGroupId ? `${groupLabel} must be selected` : ''}
            className={classes.formInput}
            data-status={role === 'customer' ? propertyRequestStatus : regionRequestStatus}
          >
            { role === 'customer' && (
              <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} statusCallback={setPropertyRequestStatus} />
            )}
            { role === 'sales' && (
              <FilterOptionsFromApi name="Region" optionsPath={regionsSummary} statusCallback={setRegionRequestStatus} />
            )}
          </TextField>
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="firstName"
            label="First Name"
            name="firstName"
            inputRef={register({ required: true, minLength: 1 })}
            error={Boolean(errors.firstName)}
            helperText={errors.firstName ? 'First name is required' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="lastName"
            label="Last Name"
            name="lastName"
            inputRef={register({ required: true, minLength: 1 })}
            error={Boolean(errors.lastName)}
            helperText={errors.lastName ? 'Last name is required' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="email"
            label="Email"
            name="email"
            inputRef={register({ required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i })}
            error={Boolean(errors.email)}
            helperText={errors.email ? 'Valid email is required' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <Button type="submit" className={classes.borderedButton}>Add</Button>
      </form>
    </Grid>
  );
};

export default AddUser;

