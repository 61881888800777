import { Dispatch, useContext } from 'react';
import { PageContext, PageDataProps } from '../providers/PageDataProvider';

type Action = {
  type: string;
  payload?: any;
};

export default function usePageData() : [PageDataProps, Dispatch<Action>] {
  const contextValue = useContext(PageContext);
  return contextValue;
}
