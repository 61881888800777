import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InnerMap from './InnerMap';

type propTypes = { children: any, fullHeight?: boolean };

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    flexBasis: 'calc(50% - 5px)',
    marginBottom: '10px'
  },
  chart: {
    height: 200
  },
  header: {
    padding: '5px',
    fontSize: '14pt',
    background: '#ecedef'
  },
  map: (props) => {
    const typedProps = props as propTypes;
    return {
      height: typedProps.fullHeight ? 'calc(100vh - 60px)' : 450
    };
  }
}));

interface ILocation {
  lat: number,
  lng: number,
  radius?: number,
}

const key = process.env.REACT_APP_GOOGLE_API_ID
  ? `${process.env.REACT_APP_GOOGLE_API_ID}`
  : 'AIzaSyClSqKkcXJsJSzWU4LtyQFfagBzk97OzIQ';

export const LocationMap = (props: propTypes) : ReactElement => {
  const { children } = props;

  const classes = useStyles(props);
  return (
    <InnerMap
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&key=${key}&libraries=geometry,drawing`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div />}
      mapElement={<div className={classes.map} />}
    >
      {children}

    </InnerMap>
  );
};
