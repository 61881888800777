import React, { ReactElement, useEffect, useMemo } from 'react';
import TableRow from '@material-ui/core/TableRow';
import { TableCell } from '@material-ui/core';
import EnhancedTable from '../../Table/EnhancedTable';
import { IUnitDataItem } from '../../../interfaces/units';
import usePaginationProps from '../../Table/usePaginationProps';

interface IColumn{
  isUnique?: boolean;
  id: keyof IUnitDataItem;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  formatter?: (val: any) => string;
}

interface IUnitHistoryTableProps {
  history: Array<IUnitDataItem>,
  previousHistoryComposite?: {[key:string]: string | number},
  columns: Array<IColumn>,
  rowsPerPage?: number
}

export const UnitHistoryTable = ({ history: rows, previousHistoryComposite, columns, rowsPerPage: rowsPerPageProp }: IUnitHistoryTableProps) : ReactElement => {

  const paginationProps = usePaginationProps('', { rowsPerPage: rowsPerPageProp });
  const { rowsPerPage, page, setPage } = paginationProps;

  useEffect( () => {
    //reset to page 0 when total rows changes
    setPage(0);
  }, [setPage, rows.length]);

  const reversedRowsWithRunningCompositeState = useMemo(() => {
    const runningCompositeRow : IUnitDataItem = previousHistoryComposite ? { ...previousHistoryComposite } : { ...rows[0] };
    const keys = columns.map( (col) => col.id);

    return rows.map((row: IUnitDataItem) => {
      const compositeRow : IUnitDataItem = {
        time: row.time
      };
      keys.forEach((key) => {
        if (key==='time') {
          return;
        }
        if (row[key] || row[key] === 0) {
          runningCompositeRow[key] = row[key];
        }
        compositeRow[key] = runningCompositeRow[key];
      });
      if (compositeRow['errorCode'] === 0) {
        compositeRow['errorCodeDescription'] = '';
      }
      return compositeRow;
    }).reverse();
  }, [previousHistoryComposite, rows, columns]);

  const processedRows = useMemo(() => {
    if (!reversedRowsWithRunningCompositeState || reversedRowsWithRunningCompositeState.length < 1 || !columns || columns.length < 1) {
      return <></>;
    }

    // this is a table of state that is already all fetched (due to need to show chart), so local-pagination only
    return reversedRowsWithRunningCompositeState.slice(page*rowsPerPage, (page+1)*rowsPerPage).map((row: IUnitDataItem, index: number) => {
      const labelId = `row-${row.time ? row.time.toString() : index}`;
      const cells =  columns.map( (col) => (
        <TableCell size="small" align="center" key={col.id}>
          { col.formatter ? col.formatter.call(this, row[col.id]) : row[col.id] }
        </TableCell>
      ));

      return (
        <TableRow
          hover
          tabIndex={-1}
          key={labelId}
        >
          <TableCell component="th" align="center" id={labelId} scope="row" />
          {cells}
        </TableRow>
      );
    });
  }, [reversedRowsWithRunningCompositeState, columns, page, rowsPerPage] );

  return (
    <EnhancedTable
      columns={columns}
      totalCount={rows.length}
      {...paginationProps}
    >
      {processedRows}
    </EnhancedTable>
  );
};
