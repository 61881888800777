/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, ReactElement, useEffect } from 'react';
import { Button, FormControl, Grid, Backdrop, CircularProgress, InputLabel, Select, Input, FormHelperText  } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { useStyles } from '../sectionStyle';
import { useForm } from 'react-hook-form';
import { IRequestStatus } from '../../interfaces/util';
import ApiWrapper from '../../ApiWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { propertiesSummary } from '../../endpoints/properties/properties';
import { regionsSummary } from '../../endpoints/regions/regions';
import { editUser as editUserEndpoint } from '../../endpoints/users';
import cn from 'classnames';

type TUserFormData = {
  firstName: string;
  lastName: string;
  role: string;
  experienceGroupId: string;
}

interface ParamTypes {
  id: string
}

const EditUser = () : React.ReactElement => {
  const { id } = useParams<ParamTypes>();
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [updateRequestStatus, setUpdateRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [preexistingExperienceGroupId, setPreexistingExperienceGroupId] = useState('');
  const [role, setRole] = useState('');
  const [propertyRequestStatus, setPropertyRequestStatus] = useState('init');
  const [regionRequestStatus, setRegionRequestStatus] = useState('init');
  const [modalDismissed, setModalDismissed] = useState(true);
  const history = useHistory();

  const dismissModal = () => {
    setModalDismissed(true);
  };

  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );
  const { register, handleSubmit, setValue, errors } = useForm<TUserFormData>();

  useEffect( () => {
    const fetchData = async () => {
      setModalDismissed(false);
      setRequestStatus({ loading: true, success: false, error: false });
      const response = await ApiWrapper({
        url: editUserEndpoint(id),
        method: 'GET'
      });
      setRequestStatus({ loading: false, success: true, error: false });

      const {
        firstName, lastName, email, experienceGroupId, role: existingRole
      } = response.data;
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('email', email);
      setValue('experienceGroupId', experienceGroupId);
      setValue('role', existingRole);
      setRole(existingRole);
      setPreexistingExperienceGroupId(experienceGroupId);

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => dismissModal);
      setModalDismissed(true);
    };

    fetchData().catch( (error) => {
      setRequestStatus({ loading: false, success: false, error: true });
      setModalAction( () => () => {
        history.push('/users');
        return null;
      } );
      console.error(error);
    });
  }, [history, id, setValue]);

  const onSubmit = async (data: TUserFormData): Promise<void> => {

    setModalDismissed(false);

    try {
      setUpdateRequestStatus({ loading: true, success: false, error: false });
      await ApiWrapper({
        url: editUserEndpoint(id),
        method: 'PATCH',
        data: data
      });
      setUpdateRequestStatus({ loading: false, success: true, error: false });

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => () => {
        history.push('/users');
        return null;
      } );
    } catch (error) {
      setUpdateRequestStatus({ loading: false, success: false, error: true });
      console.error(error);
    }
  };

  const handlePropertyStatus = (status:string) => {
    setPropertyRequestStatus(status);
  };

  useEffect( () => {
    setValue('experienceGroupId', preexistingExperienceGroupId);
  }, [propertyRequestStatus, regionRequestStatus, preexistingExperienceGroupId, setValue]);

  const hideExperienceGroupInput = role.length === 0 || ['admin', 'service', 'production'].indexOf(role) !== -1;
  const groupLabel = role === 'customer' ? 'Property' : 'Region';

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="edit-user-backdrop" onClick={modalAction} open={!modalDismissed} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {( requestStatus.error || updateRequestStatus.error ) && (<span className={classes.loadingError}>Could not load. Please refresh and try again.</span>)}
        {updateRequestStatus.success && (<span className={classes.loadingError}>User updated successfully.</span>)}
      </Backdrop>
      <PageDataConfig title="Edit User" />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
        <FormControl className={classes.formControl}>
          <InputLabel shrink>Role</InputLabel>
          <Select
            id="role"
            name="role"
            native
            inputProps={{ 'aria-label': 'Role' }}
            inputRef={register({ required: true })}
            error={Boolean(errors.role)}
            className={classes.formInput}
            onChange={
              (e) => {
                setRole( e.target.value );
              }
            }
          >
            <option value="">Role</option>
            <option value="admin">Admin</option>
            <option value="service">Service</option>
            <option value="production">Production</option>
            <option value="sales">Sales</option>
            <option value="customer">Customer</option>
          </Select>
          <FormHelperText>{errors.role ? 'Role must be selected' : ''}</FormHelperText>

        </FormControl>
        <FormControl className={cn({
          [classes.formControl]: true,
          [classes.hiddenInput]: hideExperienceGroupInput
        })}>
          <InputLabel htmlFor="experienceGroupId" shrink>{groupLabel}</InputLabel>
          <Select
            id="property"
            data-testid="property-options"
            name="experienceGroupId"
            disabled={hideExperienceGroupInput}
            inputProps={{ 'aria-label': 'Property' }}
            placeholder={hideExperienceGroupInput ? 'N/A' : ''}
            style={hideExperienceGroupInput ? { display: 'none' } : undefined}
            native
            inputRef={register({ required: !hideExperienceGroupInput })}
            error={Boolean(errors.experienceGroupId)}
            className={classes.formInput}
            data-status={role === 'customer' ? propertyRequestStatus : regionRequestStatus}
          >
            { role === 'customer' && (
              <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} statusCallback={setPropertyRequestStatus} />
            )}
            { role === 'sales' && (
              <FilterOptionsFromApi name="Region" optionsPath={regionsSummary} statusCallback={setRegionRequestStatus} />
            )}
          </Select>
          <FormHelperText>{errors.experienceGroupId ? `${groupLabel} must be selected` : ''}</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel shrink>First Name</InputLabel>
          <Input
            id="firstName"
            name="firstName"
            inputProps={{ 'aria-label': 'First Name' }}
            inputRef={register({ required: true, minLength: 1 })}
            error={Boolean(errors.firstName)}
            className={classes.formInput}
          />
          <FormHelperText>{errors.firstName ? 'First name is required' : ''}</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel shrink>Last Name</InputLabel>
          <Input
            id="lastName"
            name="lastName"
            inputProps={{ 'aria-label': 'Last Name' }}
            inputRef={register({ required: true, minLength: 1 })}
            error={Boolean(errors.lastName)}
            className={classes.formInput}
          />
          <FormHelperText>{errors.lastName ? 'Last name is required' : ''}</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel shrink>Email</InputLabel>
          <Input
            id="email"
            inputProps={{ 'aria-label': 'Email' }}
            name="email"
            disabled
            inputRef={register({ required: true, minLength: 3 })}
            className={classes.formInput}
          />
        </FormControl>

        <Button type="submit" className={classes.borderedButton}>Save</Button>
      </form>
    </Grid>
  );
};

export default EditUser;

