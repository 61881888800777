import React from 'react';
import { useStyles } from './sectionStyle';

const defaultCondition = (val:string | number) => Boolean(val || val === 0);

const ValueOrPlaceholder = ({
  value,
  condition = defaultCondition,
  placeholder = '(not set)'
} :
{
  value: string | number,
  condition?: (val:string | number) => boolean,
  placeholder?: string
} ) : React.ReactElement => {
  const classes = useStyles();

  return (condition(value)
    ? <span className={classes.cellValue}>{value}</span>
    : <span className={classes.cellPlaceholder}>{placeholder}</span>);

};

export default ValueOrPlaceholder;
