import React from 'react';
import { Backdrop, Button,  makeStyles  } from '@material-ui/core';
import { useStyles as useSectionStyles } from './sectionStyle';
import cn from 'classnames';

export const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 99,
    cursor: 'default'
  },
  confirmModal: {
    width: 300,
    background: '#fff',
    padding: '0 1rem 2rem 1rem'
  },
  confirmContent: {
    padding: '1rem',
    textAlign: 'center'
  },
  confirmModalActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '1rem 0'
  }
}));

type TProps = {
  children : React.ReactChild | React.ReactChild[];
  yes: () => void;
  no: () => void;
  yesText?: string;
  noText?: string
}

const Confirm = ({ children, yes, no, yesText = 'OK', noText = 'Cancel' } : TProps ) : React.ReactElement => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  return (
    <Backdrop open className={classes.backdrop} onClick={ (e) => {
      e.stopPropagation();
    } }>
      <div className={classes.confirmModal} >
        <div className={classes.confirmContent}>
          {children}
        </div>
        <div className={classes.confirmModalActions}>
          <Button className={sectionClasses.borderedButton} data-testid="cancel-button" onClick={ (e) => {
            e.preventDefault();
            e.stopPropagation();
            no();
          } }>{noText}</Button>
          <Button className={cn(sectionClasses.borderedButton, sectionClasses.button)} data-testid="confirm-button" onClick={ (e) => {
            e.preventDefault();
            e.stopPropagation();
            yes();
          } }>{yesText}</Button>
        </div>
      </div>
    </Backdrop>
  );
};

export default Confirm;
