import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles as useSectionStyles } from '../../sectionStyle';
import { Card } from '@material-ui/core';
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@material-ui/icons';

type propTypes = {
  name: string;
  machineSN: string;
  property: string;
  vehicle: string;
  connected: boolean | null;
};

export const Header = ({ name, machineSN, property, vehicle, connected }: propTypes) : ReactElement => {
  const classes = makeStyles(() => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      background: '#ecdef'
    },
    spanKey: {
      margin: '0 15px 0 25px',
      fontFamily: 'Lato',
      fontWeight: 'bold'
    },
    spanValue: {
      fontFamily: 'Lato',
      marginRight: '30px'
    }
  }))();

  const sectionClasses = useSectionStyles();

  return (
    <Card square className={classes.root}>
      <span className={classes.spanKey}>{name}</span>
      <span className={classes.spanKey}>{
        connected
          ? <LinkIcon aria-label="connected" className={sectionClasses.connectedIcon} />
          : <LinkOffIcon aria-label="not connected" className={sectionClasses.notConnectedIcon} />}
      </span>
      { connected !== null && (
        <span className={classes.spanValue}>{connected ? <>Connected</> : <>Not Connected</> }</span>
      )}
      <span className={classes.spanKey}>Machine S/N: </span>
      <span className={classes.spanValue}>{machineSN}</span>
      <span className={classes.spanKey}>Property: </span>
      <span className={classes.spanValue}>{property}</span>
      <span className={classes.spanKey}>Vehicle: </span>
      <span className={classes.spanValue}>{vehicle}</span>
    </Card>
  );
};
