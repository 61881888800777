/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { ReactElement, useState, useMemo, useEffect, useRef, ChangeEvent } from 'react';
import { InputBase, Card, List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyles } from '../material-theme-styles/default';
import { IRequestStatus } from '../interfaces/util';
import { ISearchItem } from '../interfaces/general';
import ApiWrapper from '../ApiWrapper';
import { searchEndpoint } from '../endpoints/general/';
import cn from 'classnames';
import useOutsideClickCallback from './useOutsideClickCallback';

const ListItemLink = (props:any) : ReactElement => {
  return <ListItem button component={Link} {...props} />;
};

const Search = () : ReactElement => {
  const classes = useStyles();
  const [searchInput, setSearchInput] = useState<string>('');
  const [results, setResults] = useState<ISearchItem[]>([]);
  const [hasUserFocus, setHasUserFocus] = useState(false);
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const isMounted = useRef(true);
  const searchRef = useRef();

  useOutsideClickCallback(searchRef, () => {
    setHasUserFocus(false);
  });

  const areResultsOpen = useMemo( () => {
    if (!hasUserFocus || searchInput.length < 1) {
      return false;
    }
    return requestStatus.loading || requestStatus.success;
  }, [searchInput, hasUserFocus, requestStatus]);


  // Track if not mounted so state isnt updated on non-existent component
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Update search results when search input changes
  useEffect(() => {
    if (searchInput.length < 1) {
      return;
    }
    setRequestStatus({ loading: true });

    const debouncedSearch = setTimeout(async () => {
      try {
        const response = await ApiWrapper({
          url: searchEndpoint,
          method: 'GET',
          params: { q: searchInput }
        });
        if (isMounted.current) {
          setRequestStatus({ loading: false, success: true, error: false });
          setResults(response.data.items);
        }
      } catch (e) {
        if (isMounted.current) {
          setRequestStatus({ loading: false, success: false, error: true });
        }
        console.error('Error Searching:', e);
      }
    }, 250);

    return () => {
      // Clears debounce, not async
      clearTimeout(debouncedSearch);
    };
  }, [searchInput]);


  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchInput(e.currentTarget.value.trim());
  };

  const getResults = () => {
    if (!results || results.length < 1 ) {
      return <span className={classes.searchResultMessage}>No results found</span>;
    }

    type TResource = 'region' | 'property' | 'vehicle' | 'unit' | 'machineSN'

    const typeLink = (type: TResource, id: string) : string => {
      return {
        region: `/properties/?region=${id}`,
        property: `/vehicles/?property=${id}`,
        vehicle: `/units/?vehicle=${id}`,
        unit: `/units/?id=${id}`,
        machineSN: `/units/?id=${id}`
      }[type];
    };

    return results.slice(0, 8).map( (result) => (
      <ListItemLink to={typeLink(result.type as TResource, result.id)} onClick={() => { setSearchInput(''); }} >
        <ListItemText primary={result.name} secondary={`(${result.type})`}/>
      </ListItemLink>
    ));
  };

  return (
    <div className={classes.searchContainer} ref={searchRef} onClick={() => setHasUserFocus(true)}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <FontAwesomeIcon icon={['fas', 'search']} />
        </div>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          onChange={handleChange}
          value={searchInput}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
      <Card
        square
        aria-label="Search Results"
        aria-hidden={!areResultsOpen}
        style={{ display: areResultsOpen ? 'block' : 'none' }}
        className={cn({ [classes.searchResults]: true, [classes.searchResultsVisible]: areResultsOpen })}>
        <List component="nav">
          {
            requestStatus.loading
              ? <span className={classes.searchResultMessage}>Loading...</span>
              : getResults()
          }
        </List>
      </Card>
    </div>
  );
};

export default Search;
