import React, { ReactElement, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Login } from '../components/Login';
import { Navbar } from '../components/Navbar';
import { Topbar } from '../components/Topbar';
import { Alerts } from '../components/Alerts/Alerts';
import Overview from '../components/Overview/Overview';
import { AuthContext } from '../state-manipulation/providers/AuthWrapper';
import { useStyles } from '../material-theme-styles/default';
import { Vehicles } from '../components/Vehicles/Vehicles';
import { Units } from '../components/Units/Units';
import Unit from '../components/Units/Unit/Unit';
import EditUnit from '../components/Units/EditUnit';
import { Debug } from '../components/Units/Debug/Debug';
import { Users } from '../components/Users/Users';
import { Properties } from '../components/Properties/Properties';
import { Regions } from '../components/Regions/Regions';
import AddProperty from '../components/Properties/AddProperty';
import EditProperty from '../components/Properties/EditProperty';
import EditVehicle from '../components/Vehicles/EditVehicle';
import AddVehicle from '../components/Vehicles/AddVehicle';
import AddUser from '../components/Users/AddUser';
import EditUser from '../components/Users/EditUser';
import Can from '../Can';
import MessagePane from '../components/MessagePane';
import ScrollToTop from '../components/ScrollToTop';

const NotAllowed = () => <MessagePane title="Not Allowed" message="You are not allowed to access this page" />;

export default function StandardLayout() : ReactElement {
  const { isAuthenticated } = useContext(AuthContext);
  const classes = useStyles();

  if (!isAuthenticated) {
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <Switch>
            <Route component={Login} />
          </Switch>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ScrollToTop />
      <div className={classes.sidebar}><Navbar /></div>
      <div className={classes.header}><Topbar /></div>
      <div className={classes.content}>
        <Switch>
          <Route exact path="/">
            <Can perform="overview" no={() => <MessagePane title="Welcome" /> } yes={ () => <Overview /> } />
          </Route>
          <Route exact path="/overview">
            <Can perform="overview" no={() => <NotAllowed /> } yes={ () => <Overview /> } />
          </Route>
          <Route exact path="/alerts">
            <Can perform="alerts" no={() => <NotAllowed /> } yes={ () => <Alerts /> } />
          </Route>
          <Route exact path="/regions">
            <Can perform="regions" no={() => <NotAllowed /> } yes={ () => <Regions /> } />
          </Route>
          <Route exact path="/properties">
            <Can perform="properties" no={() => <NotAllowed /> } yes={ () => <Properties /> } />
          </Route>
          <Route exact path="/properties/add">
            <Can perform="properties:add" no={() => <NotAllowed /> } yes={ () => <AddProperty /> } />
          </Route>
          <Route exact path="/property/:id/edit">
            <Can perform="properties:edit" no={() => <NotAllowed /> } yes={ () => <EditProperty /> } />
          </Route>
          <Route exact path="/vehicles">
            <Can perform="vehicles" no={() => <NotAllowed /> } yes={ () => <Vehicles /> } />
          </Route>
          <Route exact path="/vehicles/add">
            <Can perform="vehicles:add" no={() => <NotAllowed /> } yes={ () => <AddVehicle /> } />
          </Route>
          <Route exact path="/vehicle/:id/edit">
            <Can perform="vehicles:edit" no={() => <NotAllowed /> } yes={ () => <EditVehicle /> } />
          </Route>
          <Route exact path="/units">
            <Can perform="units" no={() => <NotAllowed /> } yes={ () => <Units /> } />
          </Route>
          <Route exact path="/unit/:id/edit">
            <Can perform="units:edit" no={() => <NotAllowed /> } yes={ () => <EditUnit /> } />
          </Route>
          <Route exact path="/unit/:id">
            <Can perform="units" no={() => <NotAllowed /> } yes={ () => <Unit /> } />
          </Route>
          <Route exact path="/unit/:id/debug" >
            <Can perform="units:debug" no={() => <NotAllowed /> } yes={ () => <Debug /> } />
          </Route>
          <Route exact path="/users/" >
            <Can perform="users" no={() => <NotAllowed /> } yes={ () => <Users /> } />
          </Route>
          <Route exact path="/users/add" >
            <Can perform="users:add" no={() => <NotAllowed /> } yes={ () => <AddUser /> } />
          </Route>
          <Route exact path="/user/:id/edit" >
            <Can perform="users:edit" no={() => <NotAllowed /> } yes={ () => <EditUser /> } />
          </Route>
          <Route component={Login} />
        </Switch>
      </div>
    </div>
  );
}
