// eslint-disable @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router';
import { Card, Grid, CardHeader, Backdrop, CircularProgress } from '@material-ui/core';
import { AxiosPromise } from 'axios';
import cx from 'classnames';
import { useStyles } from './style';
import ApiWrapper from '../../../ApiWrapper';
import { getUnitData, getUnitMetadata } from '../../../endpoints/units';
import { getAlerts } from '../../../endpoints/alerts';
import { unitData, timeRange, IUnitDataResponse, IUnitMetadataResponse } from '../../../interfaces/units';
import { IRequestStatus } from '../../../interfaces/util';
import { IAlertsResult } from '../../../interfaces/alerts';
import { DataChart } from './DataChart';
import { GaugeChart } from './GaugeChart';
import { LocationMap } from '../LocationMap';
import { MapMeta } from './MapMeta';
import { UnitHistoryTable } from './UnitHistoryTable';
import { Header } from './Header';
import { DateRangePicker } from './DateRangePicker';
import { ThresholdCharts } from './ThresholdCharts';
import { ThresholdCommand } from './ThresholdCommand';
import { DeviceCommands } from './DeviceCommands';
import { RawDeviceInfo } from './RawDeviceInfo';
import { DataExport } from './DataExport';
import { ReportExport } from './ReportExport';
import Moment from 'react-moment';
import { Marker } from 'react-google-maps';
import MarkerNormal from '../../../assets/marker--normal.svg';
import PageDataConfig from '../../PageDataConfig';

interface ParamTypes {
  id: string
}

const gaugeChartColor = (errorCodeDescription : string | undefined) => {
  if (errorCodeDescription) {
    return '#fff';
  } else {
    return '#000';
  }
};

const gaugeChartBackgroundColor = (errorCodeDescription : string | undefined) => {
  if (errorCodeDescription === 'Normal') {
    return '#88bb55';
  } else if (errorCodeDescription) {
    return '#f45252';
  } else {
    return '#fff';
  }
};

export const Debug = () : ReactElement => {
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [deviceData, setDeviceData] = useState<unitData>({});
  const [usingDefaultRange, setUsingDefaultRange] = useState(true);
  const [visibleAttributes, setVisibleAttributes] = useState([
    'accelerationMax',
    'accelerationMaxCalibrated',
    'accelerationOver',
    'accelerationOverCalibrated',
    'rotCurrentMax',
    'sqzCurrentMax',
    'temp',
    'inputVoltageMin',
    'inputVoltageMax'
  ]);

  const toggleVisibleAttribute = (attribute: string) => {
    if (visibleAttributes.includes(attribute) ) {
      setVisibleAttributes( visibleAttributes.filter( (attr) => attr !== attribute ) );
    } else {
      setVisibleAttributes( [
        ...visibleAttributes,
        attribute
      ]);
    }
  };

  const fetchData = useCallback(async (timeRangeToLoad?: timeRange) => {

    const date = Date.now();
    const defaultTimeRange: timeRange = {
      start: date - 86400000,
      end: date,
      resolution: 30000
    };

    setRequestStatus({ loading: true });

    try {
      const metadataPromise: AxiosPromise<IUnitMetadataResponse> = ApiWrapper({
        url: getUnitMetadata(id),
        method: 'GET',
        params: { at: timeRangeToLoad ? timeRangeToLoad.end : defaultTimeRange.end }
      });

      const dataPromise: AxiosPromise<IUnitDataResponse> = ApiWrapper({
        url: getUnitData(id),
        method: 'GET',
        params: timeRangeToLoad || defaultTimeRange
      });

      const alertsPromise: AxiosPromise<IAlertsResult> = ApiWrapper({
        url: getAlerts,
        method: 'GET',
        params: {
          ...(timeRangeToLoad || defaultTimeRange),
          unitIds: id,
          rowsPerPage: 10,
          offset: 0
        }
      });
      const response = await Promise.all([metadataPromise, dataPromise, alertsPromise]);
      setRequestStatus({ loading: false, success: true, error: false });
      setDeviceData({ metadata: response[0].data, data: response[1].data, alerts: response[2].data });
    } catch (e) {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error('Error Fetching Device Data:', e);
    }
  }, [id]);

  const fetchDataWithTimeRange = async (timeRangeToLoad?: timeRange) => {
    if (usingDefaultRange) {
      setUsingDefaultRange(false);

    }
    await fetchData(timeRangeToLoad).catch( (e) => {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error('Could not fetch data with time range', e);
    });
  };

  /* Initial Load */
  useEffect(() => {
    fetchData().catch( (e) => {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error('Could not load debug data', e);
    });
  }, [fetchData]);

  const formatNumberTwoDigits = (val: string | number | undefined) => {
    if (!val) {
      return undefined;
    }
    if (typeof val === 'string') {
      return Number(Number(val).toFixed(2));
    }
    return Number(val.toFixed(2));
  };

  const formatDate = (val: number) => <Moment format="M/D/YYYY HH:mm">{val}</Moment>;

  const historyColumns = [
    {
      id: 'time', isUnique: true, numeric: true, disablePadding: true, label: 'Time', formatter: formatDate
    },
    { id: 'mainState', numeric: false, disablePadding: false, label: 'Main State' },
    { id: 'mainStateDescription', numeric: false, disablePadding: false, label: 'Main State Description' },
    { id: 'seqState', numeric: false, disablePadding: false, label: 'Sequence State' },
    { id: 'seqStateDescription', numeric: true, disablePadding: false, label: 'Sequence State Description' },
    { id: 'cycleCount', numeric: true, disablePadding: false, label: 'Cycle Count' },
    { id: 'errorCode', numeric: false, disablePadding: false, label: 'Error Code' },
    { id: 'errorCodeDescription', numeric: false, disablePadding: false, label: 'Error Code Description' },
    { id: 'temp', numeric: false, disablePadding: false, label: 'Temp °C', formatter: formatNumberTwoDigits }
  ];

  // This is not an autorefresh. Used after sending commands.
  const refreshDataIfDefaultRange = () => {
    if (usingDefaultRange) {
      setTimeout(() => {
        fetchData().catch( (e) => { console.error('Could not load debug data', e); });
      }, 1000);
    }
  };

  return (
    <div className={classes.debugRoot}>
      <Backdrop data-testid="debug-backdrop" open={Boolean(requestStatus.loading || requestStatus.error)} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {requestStatus.error && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Unit" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header
            serialNum={deviceData.metadata ? deviceData.metadata.serialNum : ''}
            firmware={deviceData.metadata ? deviceData.metadata.firmware : ''}
            architectRevLevel=""
          />
        </Grid>
        <Grid item container xs={6} className={classes.leftCardGroup}>
          <Grid item xs={8} className={classes.errorGrid}>
            <Card className={cx(classes.card, classes.errorCard)}>
              <GaugeChart
                testId="debug-error-block"
                title="Error Code"
                data={[{ val: deviceData.metadata ? deviceData.metadata.errorCodeDescription : '' }]}
                color={gaugeChartColor(deviceData?.metadata?.errorCodeDescription)}
                backgroundColor={gaugeChartBackgroundColor(deviceData?.metadata?.errorCodeDescription)}
              />
            </Card>
          </Grid>
          <Grid item xs={4} className={classes.cycleGrid}>
            <Card className={cx(classes.card, classes.cycleCard)}>
              <GaugeChart
                testId="debug-cycle-block"
                title="Cycle Count"
                data={[{ val: deviceData.metadata && deviceData.metadata.cycleCount ? deviceData.metadata.cycleCount.toString() : '' }]}
              />
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.busTrackingGrid}>
            <Card className={cx(classes.card)}>
              <Grid container>
                <Grid item xs={8}>
                  <LocationMap>
                    { deviceData.metadata && deviceData.metadata.latitude && deviceData.metadata.longitude && (
                      <Marker
                        position={{
                          lat: deviceData.metadata.latitude,
                          lng: deviceData.metadata.longitude
                        }}
                        icon={{
                          url: MarkerNormal
                        }}
                      />
                    )}
                  </LocationMap>
                </Grid>
                <Grid item xs={4}>
                  <MapMeta deviceData={deviceData} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <DateRangePicker onSubmit={fetchDataWithTimeRange} />
          <Card className={cx(classes.card, classes.realtimeChartsCard)}>
            <DataChart
              data={deviceData.data ? deviceData.data.items : []}
              title="Acceleration"
              unit="(1/1000 G)"
              chartType="line"
              toggleAttribute={toggleVisibleAttribute}
              highAlert={deviceData.metadata?.thresholds?.accelerationUpperThreshold}
              dataKeys={[
                { show: visibleAttributes.includes('accelerationMax'), attribute: 'accelerationMax', name: 'Acceleration Max', color: '#013CA6' },
                { show: visibleAttributes.includes('accelerationMaxCalibrated'), attribute: 'accelerationMaxCalibrated', name: 'Acceleration Max - Calibrated', color: '#76a6fe' },
                { show: visibleAttributes.includes('accelerationOver'), attribute: 'accelerationOver', name: 'Acceleration Over', color: '#ff0000' },
                { show: visibleAttributes.includes('accelerationOverCalibrated'), attribute: 'accelerationOverCalibrated', name: 'Acceleration Over - Calibrated', color: '#ff8888' }
              ]}
            />
            <DataChart
              data={deviceData.data ? deviceData.data.items : []}
              title="Rotation Current"
              unit="(A)"
              chartType="line"
              toggleAttribute={toggleVisibleAttribute}
              highAlert={deviceData.metadata?.thresholds?.rotationMotorCurrentUpperThreshold}
              dataKeys={[{ show: visibleAttributes.includes('rotCurrentMax'), attribute: 'rotCurrentMax', name: 'Rotation Current' }]}
            />
            <DataChart
              data={deviceData.data ? deviceData.data.items : []}
              title="Squeeze Current"
              unit="(A)"
              chartType="line"
              toggleAttribute={toggleVisibleAttribute}
              highAlert={deviceData.metadata?.thresholds?.squeezeMotorCurrentUpperThreshold}
              dataKeys={[{ show: visibleAttributes.includes('sqzCurrentMax'), attribute: 'sqzCurrentMax', name: 'Squeeze Current' }]}
            />
            <DataChart
              data={deviceData.data ? deviceData.data.items : []}
              title="Temperature"
              unit="(C)"
              chartType="line"
              toggleAttribute={toggleVisibleAttribute}
              lowAlert={deviceData.metadata?.thresholds?.instantaneousTemperatureLowerThreshold}
              highAlert={deviceData.metadata?.thresholds?.instantaneousTemperatureUpperThreshold}
              dataKeys={[{ show: visibleAttributes.includes('temp'), attribute: 'temp', name: 'Temperature' }]}
            />
            <DataChart
              data={deviceData.data ? deviceData.data.items : []}
              title="Input Voltage Min/Max"
              unit="(1/1000 V)"
              chartType="line"
              toggleAttribute={toggleVisibleAttribute}
              lowAlert={deviceData.metadata?.thresholds?.instantaneousInputVoltageLowerThreshold}
              highAlert={deviceData.metadata?.thresholds?.instantaneousInputVoltageUpperThreshold}
              dataKeys={[
                { show: visibleAttributes.includes('inputVoltageMin'), attribute: 'inputVoltageMin', name: 'Input Voltage Min', color: '#76a6fe' },
                { show: visibleAttributes.includes('inputVoltageMax'), attribute: 'inputVoltageMax', name: 'Input Voltage Max', color: '#013CA6' }
              ]}
            />
            <div className={classes.chartChild}>
              <GaugeChart
                title="Main State"
                height={95}
                data={[{ val: deviceData.metadata ? deviceData.metadata.mainStateDescription : '' }]}
              />
              <GaugeChart
                title="Sequence State"
                height={95}
                data={[{ val: deviceData.metadata ? deviceData.metadata.seqStateDescription : '' }]}
              />
            </div>
          </Card>
        </Grid>
        <Grid item xs={7}>
          <Card className={cx(classes.card, classes.threshholdCard)}>
            {deviceData.metadata && <ThresholdCharts thresholds={deviceData.metadata.thresholds} />}
          </Card>
        </Grid>
        <Grid item xs={5}>
          <CardHeader className={classes.cardHeader} title="Set Device Threshold" />
          <Card className={cx(classes.card, classes.threshholdCard, classes.placeholderText)}>
            {deviceData.metadata && (
              <ThresholdCommand
                thresholds={deviceData.metadata.thresholds}
                refreshDataIfDefaultRange={refreshDataIfDefaultRange}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={12}>
          <CardHeader className={classes.cardHeader} title="Device Commands" />
          <DeviceCommands
            alerts={deviceData.alerts ? deviceData.alerts.items : []}
            totalAlerts={deviceData.alerts ? deviceData.alerts.totalCount : 0}
            refreshDataIfDefaultRange={refreshDataIfDefaultRange}
          />
        </Grid>
        <Grid item xs={12}>
          <Card className={cx(classes.card)}>
            <CardHeader className={classes.cardHeader} title="Raw Device Info" />
            <RawDeviceInfo data={deviceData.metadata ? deviceData.metadata : {}} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={cx(classes.card)}>
            <CardHeader className={classes.cardHeader} title="History" />
            <UnitHistoryTable history={deviceData.data ? deviceData.data.items : []} previousHistoryComposite={deviceData.data ? deviceData.data.previousCompositeState : undefined} columns={historyColumns}/>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={cx(classes.card)}>
            <CardHeader className={classes.cardHeader} title="Data Export" />
            <DataExport unitId={id} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={cx(classes.card)}>
            <CardHeader className={classes.cardHeader} title="Request Secured/Unsecured Location Report" />
            <ReportExport unitId={id} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
