import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import { ArrowIcon } from './ArrowIcons';

type propTypes = {
  title: string;
  data: { val: string | number; direction?: string }[];
  height?: number;
  color?: string;
  backgroundColor?: string;
  testId?: string;
};

export const GaugeChart = (props: propTypes & { className?: string }) : ReactElement => {
  const {
    title, data, height, color, backgroundColor, testId
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: height || '100%',
      flexBasis: 'calc(50% - 5px)',
      marginBottom: '10px',
      paddingBottom: '15px'
    },
    gauge: {
      fontSize: '150%',
      fontFamily: 'Lato',
      fontWeight: 'bold',
      height: height || '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      color: color || '#000',
      backgroundColor: backgroundColor || '#fff'
    },
    header: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      padding: '10px',
      marginBottom: '15px',
      fontSize: '10pt',
      background: '#f2f2f2'
    }
  }));

  const classes = useStyles();
  return (
    <Card square className={classes.root} data-testid={ testId ? testId : 'notestid' }>
      <span className={classes.header}>{title}</span>
      {data.map((it) => (
        <span className={classes.gauge}>
          {it.direction && <ArrowIcon marginRight={10} direction={it.direction} />}
          {it.val || it.val === 0 ? it.val : '—'}
        </span>
      ))}
    </Card>
  );
};
