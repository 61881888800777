/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, ReactElement, useEffect } from 'react';
import { Button, FormControl, Grid, Backdrop, CircularProgress, InputLabel, Input, FormHelperText, Select  } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { useStyles } from '../sectionStyle';
import { useForm } from 'react-hook-form';
import { IRequestStatus } from '../../interfaces/util';
import ApiWrapper from '../../ApiWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { propertiesSummary } from '../../endpoints/properties/properties';
import { editVehicle as editVehicleEndpoint } from '../../endpoints/vehicles/vehicles';
import { TVehicleFormData, TVehiclePostRequest } from '../../interfaces/vehicles';
import Can from '../../Can';

interface ParamTypes {
  id: string
}

const EditVehicle = () : React.ReactElement => {
  const { id } = useParams<ParamTypes>();
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [updateRequestStatus, setUpdateRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [preexistingProperty, setPreexistingProperty] = useState('');
  const [propertyRequestStatus, setPropertyRequestStatus] = useState('init');
  const [modalDismissed, setModalDismissed] = useState(true);
  const history = useHistory();

  const dismissModal = () => {
    setModalDismissed(true);
  };

  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );
  const { register, formState, handleSubmit, setValue, errors } = useForm<TVehicleFormData>();

  useEffect( () => {
    const fetchData = async () => {
      setModalDismissed(false);
      setRequestStatus({ loading: true, success: false, error: false });
      const response = await ApiWrapper({
        url: editVehicleEndpoint(id),
        method: 'GET'
      });
      setRequestStatus({ loading: false, success: true, error: false });

      const { name, routes, active, property } = response.data;
      setValue('name', name);
      setValue('routes', routes);
      setValue('active', active);
      setValue('property', property);
      setPreexistingProperty(property);

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => dismissModal);
      setModalDismissed(true);
    };

    fetchData().catch( (error) => {
      setRequestStatus({ loading: false, success: false, error: true });
      setModalAction( () => () => {
        history.push('/vehicles');
        return null;
      } );
      console.error(error);
    });
  }, [history, id, setValue]);

  const onSubmit = async (data: TVehicleFormData): Promise<void> => {
    const formattedData : TVehiclePostRequest = {
      ...data,
      active: data.active === 'true'
    };
    setModalDismissed(false);

    try {
      setUpdateRequestStatus({ loading: true, success: false, error: false });
      await ApiWrapper({
        url: editVehicleEndpoint(id),
        method: 'PATCH',
        data: formattedData
      });
      setUpdateRequestStatus({ loading: false, success: true, error: false });

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => () => {
        history.push('/vehicles');
        return null;
      } );
    } catch (error) {
      setUpdateRequestStatus({ loading: false, success: false, error: true });
      console.error(error);
    }
  };

  useEffect( () => {
    setValue('property', preexistingProperty);
  }, [propertyRequestStatus, preexistingProperty, setValue]);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="edit-vehicle-backdrop" onClick={modalAction} open={!modalDismissed} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {( requestStatus.error || updateRequestStatus.error ) && (<span className={classes.loadingError}>Could not load. Please refresh and try again.</span>)}
        {updateRequestStatus.success && (<span className={classes.loadingError}>Vehicle updated successfully.</span>)}
      </Backdrop>
      <PageDataConfig title="Edit Vehicle" />
      <form className={classes.form} onSubmit={ handleSubmit(onSubmit)} >
        <Can perform="properties" yes={() => (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="property" shrink>Property</InputLabel>
            <Select
              id="property"
              name="property"
              data-testid="property-options"
              native
              inputRef={register({ required: true })}
              error={Boolean(errors.property)}
              className={classes.formInput}
              data-status={propertyRequestStatus}
            >
              <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} statusCallback={setPropertyRequestStatus}/>
            </Select>
            <FormHelperText>{errors.property ? 'Property must be selected' : ''}</FormHelperText>
          </FormControl>
        )} />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="name" shrink>Name</InputLabel>
          <Input
            id="name"
            name="name"
            inputRef={register({ required: true, minLength: 3 })}
            error={Boolean(errors.name)}
            className={classes.formInput}
          />
          <FormHelperText>{errors.name ? 'Vehicle name is required' : ''}</FormHelperText>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="routes" shrink>Routes</InputLabel>
          <Input
            id="routes"
            name="routes"
            inputRef={register()}
            error={Boolean(errors.routes)}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="active" shrink>Active</InputLabel>
          <Select
            id="active"
            name="active"
            native
            inputRef={register({ required: true })}
            error={Boolean(errors.active)}
            className={classes.formInput}
          >
            <option value=""></option>
            <option value="true">True</option>
            <option value="false">False</option>
          </Select>
          <FormHelperText>{errors.property ? 'Active is required' : ''}</FormHelperText>
        </FormControl>
        <Button type="submit" className={classes.borderedButton}>Save</Button>
      </form>
    </Grid>
  );
};

export default EditVehicle;

