import React, { useState, useRef, useEffect, ReactElement } from 'react';
import { AxiosRequestConfig, AxiosPromise } from 'axios';
import {
  Backdrop,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core';
import { Notifications, Speed } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApiWrapper from '../../ApiWrapper';
import { AlertHistory } from './AlertHistory';
import { CycleHistory } from './CycleHistory';
import { TotalAlertsCard } from './TotalAlertsCard';
import {
  overviewStatusUrl,
  overviewHealthUrl,
  overviewCyclesUrl,
  overviewCycleHistoryUrl,
  overviewAlertsUrl,
  overviewAlertHistoryUrl
} from '../../endpoints/overview';
import {
  IOverviewStatus,
  IOverviewHealth,
  IOverviewCycles,
  IOverviewCycleHistory,
  IOverviewAlerts,
  IOverviewAlertHistory
} from '../../interfaces/overview';
import { useStyles } from './style/OverviewStyle';
import { useStyles as useSectionStyles } from '../sectionStyle';
import { PerfCard } from './Cards';
import PageDataConfig from '../PageDataConfig';
import UnitsMap from '../Units/UnitsMap/UnitsMap';

type dashboardData = {
  overviewStatus: IOverviewStatus;
  overviewHealth: IOverviewHealth;
  overviewCycles: IOverviewCycles;
  overviewCycleHistory: IOverviewCycleHistory;
  overviewAlerts: IOverviewAlerts;
  overviewAlertHistory: IOverviewAlertHistory;
};

export default function Overview() : ReactElement {

  const sectionClasses = useSectionStyles();

  const isMounted = useRef(true);
  // Track if not mounted so state isnt updated on non-existent component
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [requestStatus, setRequestStatus] = useState('init');

  const [dashboardData, setDashboardData] = useState<dashboardData>({
    overviewStatus: {
      active: -1,
      inactive: -1
    },
    overviewHealth: {
      normal: -1,
      warning: -1,
      critical: -1
    },
    overviewCycles: {
      daily: -1,
      weekly: -1,
      monthly: -1
    },
    overviewCycleHistory: {
      history: []
    },
    overviewAlerts: {
      info: -1,
      warning: -1,
      error: -1,
      critical: -1
    },
    overviewAlertHistory: {
      history: []
    }
  });
  const classes = useStyles();


  const loadData = async () : Promise<void> => {
    const params: AxiosRequestConfig = {
      data: {
        filters: []
      }
    };

    setRequestStatus('loading');

    const overviewStatus: AxiosPromise<IOverviewStatus> = ApiWrapper({
      url: overviewStatusUrl,
      method: 'GET',
      params
    });

    const overviewHealth: AxiosPromise<IOverviewHealth> = ApiWrapper({
      url: overviewHealthUrl,
      method: 'GET',
      params
    });

    const overviewCycles: AxiosPromise<IOverviewCycles> = ApiWrapper({
      url: overviewCyclesUrl,
      method: 'GET',
      params
    });

    const overviewAlerts: AxiosPromise<IOverviewAlerts> = ApiWrapper({
      url: overviewAlertsUrl,
      method: 'GET',
      params
    });

    const overviewAlertHistory:
    AxiosPromise<IOverviewAlertHistory> = ApiWrapper({
      url: overviewAlertHistoryUrl,
      method: 'GET',
      params
    });

    const overviewCycleHistory:
    AxiosPromise<IOverviewCycleHistory> = ApiWrapper({
      url: overviewCycleHistoryUrl,
      method: 'GET',
      params
    });

    const data = await Promise.all([
      overviewStatus,
      overviewHealth,
      overviewCycles,
      overviewCycleHistory,
      overviewAlerts,
      overviewAlertHistory
    ]).catch( (e) => {
      setRequestStatus('error');
      console.error(e);
    });

    if (!data) {
      return;
    }

    if (isMounted.current) {

      setDashboardData({
        overviewStatus: {
          active: data[0].data.active,
          inactive: data[0].data.inactive
        },
        overviewHealth: {
          normal: data[1].data.normal,
          warning: data[1].data.warning,
          critical: data[1].data.critical
        },
        overviewCycles: {
          monthly: data[2].data.monthly,
          weekly: data[2].data.weekly,
          daily: data[2].data.daily
        },
        overviewCycleHistory: {
          history: data[3].data.history
        },
        overviewAlerts: {
          info: data[4].data.info,
          warning: data[4].data.warning,
          critical: data[4].data.critical,
          error: data[4].data.error
        },
        overviewAlertHistory: {
          history: data[5].data.history
        }
      });

      setRequestStatus('success');

    }
  };

  useEffect(() => {
    loadData().catch( (error) => {
      console.error('Could not load overview data', error);
    });
  }, []);

  return (
    <div className={classes.overFlowRoot}>
      <Backdrop data-testid="overview-backdrop" open={Boolean(requestStatus === 'loading' || requestStatus === 'error')} className={sectionClasses.backdrop}>
        {requestStatus === 'loading' && (<CircularProgress color="inherit" />)}
        {requestStatus === 'error' && (<span className={sectionClasses.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Overview" />
      {/* TopRow */}
      <Grid className={classes.topRow} container spacing={2}>

        <Grid item sm={12} md={6}>
          <div className={classes.sectionTitle}>
            <Speed />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              role="heading">
              Unit Status
            </Typography>
          </div>
          <PerfCard
            data={[
              {
                name: 'Active Units',
                color: 'blue',
                value: dashboardData.overviewStatus.active
              },
              {
                name: 'Non-Active Units',
                color: 'gray',
                value: dashboardData.overviewStatus.inactive
              }
            ]}
            title="Unit Status"
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <div className={classes.sectionTitle}>
            <Speed />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              role="heading">
              Unit Condition
            </Typography>
          </div>
          <PerfCard
            data={[
              {
                name: 'Service Required',
                color: 'red',
                value: dashboardData.overviewHealth.critical,
                link: '/alerts?level=service-required',
                linkPermission: 'alerts'
              },
              {
                name: 'Warning',
                color: 'yellow',
                value: dashboardData.overviewHealth.warning,
                link: '/alerts?level=warning',
                linkPermission: 'alerts'
              },
              {
                name: 'Normal',
                color: 'green',
                value: dashboardData.overviewHealth.normal,
                link: '/alerts',
                linkPermission: 'alerts'
              }
            ]}
            title="Unit Health"
          />
        </Grid>
      </Grid>
      {/* 2nd Row */}
      <Grid className={classes.middleRow} container spacing={2}>
        <Grid item sm={12} md={4}>
          <div className={classes.sectionTitle}>
            <Notifications />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              role="heading">
              Recent Alerts
            </Typography>
          </div>
          <TotalAlertsCard data={dashboardData.overviewAlerts} />
        </Grid>
        <Grid item sm={12} md={8}>
          <div className={classes.sectionTitle}>
            <FontAwesomeIcon
              className={classes.sectionTitleIcon}
              icon={['fas', 'history']} />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              role="heading">
              Alert History
            </Typography>
          </div>
          <AlertHistory data={dashboardData.overviewAlertHistory} />
        </Grid>
      </Grid>
      {/* 3rd Row */}
      <Grid className={classes.middleRow} container spacing={2}>
        <Grid item sm={12} md={8}>
          <div className={classes.sectionTitle}>
            <FontAwesomeIcon
              className={classes.sectionTitleIcon}
              icon={['fas', 'history']} />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              role="heading">
              Cycle History
            </Typography>
          </div>
          <CycleHistory data={dashboardData.overviewCycleHistory} />
        </Grid>
        <Grid item sm={12} md={4}>
          <div className={classes.sectionTitle}>
            <Speed />
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              role="heading">
              Cycles
            </Typography>
          </div>
          <PerfCard
            data={[
              {
                name: 'Past Month',
                color: 'yellow',
                value: dashboardData.overviewCycles.monthly
              },
              {
                name: 'Past Week',
                color: 'green',
                value: dashboardData.overviewCycles.weekly
              },
              {
                name: 'Past 24 Hours',
                color: 'blue',
                value: dashboardData.overviewCycles.daily
              }
            ]}
            title="Total Cycles"
          />
        </Grid>
      </Grid>
      {/* 4th Row */}
      <Grid className={classes.middleRow} container spacing={2}>
        <Grid item sm={12}>
          <UnitsMap />
        </Grid>
      </Grid>

    </div>
  );
}
