import React, { useState, FunctionComponent } from 'react';
import PropTypes from 'prop-types';

type SetValue = (value: any) => void;

export interface AuthContextInterface {
  isAuthenticated: boolean;
  setIsAuthenticated: SetValue;
}

export const AuthContext = React.createContext<AuthContextInterface>( { isAuthenticated: false, setIsAuthenticated: () => true });

// export const AuthWrapper : FunctionComponent<AuthProps> = ({children}) => {
// export default function AuthWrapper({ children }) {
const AuthWrapper: FunctionComponent = ({ children }) => {
  // const token: string | null = sessionStorage.getItem("JWT_TOKEN");
  const jwtExpire = sessionStorage.getItem('JWT_EXPIRE');
  const expire: number = jwtExpire ? Number(jwtExpire) : 0;
  const isExpired: boolean = Math.floor(Date.now() / 1000) > expire;
  const [isAuthenticated, setIsAuthenticated] = useState(!isExpired);
  // const { siteId } = useParams();
  // console.log("TCL: AuthWrapper -> siteId", siteId);

  // const history = useHistory();
  // console.log("TCL AuthWrapper:  -> history", history);
  // const location = useLocation();
  // console.log("TCL AuthWrapper:  -> location", location);
  // let test = new URLSearchParams(location.search);
  // const test2 = queryString.parse(location.search);
  // console.log("TCL: AuthWrapper -> test2", test2);
  // console.log("TCL: AuthWrapper -> test", test.getAll("siteId"));
  // const matchSite = useRouteMatch("/customer/site?siteId");

  // console.log("TCL: AuthWrapper -> match", matchSite);
  // console.log("TCL: AuthWrapper -> ", sessionStorage.getItem("JWT_EXPIRE"));

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthWrapper;

AuthWrapper.propTypes = {
  children: PropTypes.node
};

AuthWrapper.defaultProps = {
  children: React.Children
};
