import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.mobileStepper - 1,
    color: '#fff',
    top: '60px'
  },
  loadingError: {
    fontSize: '2rem',
    fontFamily: 'sans-serif',
    padding: '5rem',
    textAlign: 'center'
  },
  debugRoot: {
    flexGrow: 1,
    width: '100%'
  },
  card: {
    borderRadius: 'unset'
  },
  realtimeChartsCard: {
    height: '650px',
    padding: '10px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  leftCardGroup: {
    width: '100%',
    alignContent: 'flex-start'
  },
  errorCard: {
    flexGrow: 3,
    height: '290px'
  },
  errorGrid: {
    paddingRight: 'inherit',
    paddingBottom: 'inherit'
  },
  cycleCard: {
    height: '290px',
    paddingBottom: 'inherit'
  },
  unitHistoryCard: {
    height: '400px',
    overflowY: 'scroll'
  },
  cycleGrid: {
    paddingLeft: 'inherit'
  },
  busTrackingGrid: {
    paddingTop: 'inherit',
    paddingBottom: 'inherit'
  },
  threshholdCard: {
    height: '340px'
  },
  header: {
    height: '50px'
  },
  cardHeader: {
    padding: '5px',
    color: '#222222',
    fontSize: '10pt',
    background: '#f2f2f2'
  },
  chartChild: {
    flexBasis: 'calc(50% - 5px)',
    width: '48%',
    marginBottom: '10px'
  },
  mapMeta: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mapMetaItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '14pt',
    fontFamily: 'Lato',
    flexBasis: '50%',
    backgroundColor: '#fff'
  },
  darkMeta: {
    backgroundColor: '#3d3d3d',
    color: '#fff'
  },
  emphasis: {
    fontWeight: 'bold'
  },
  placeholderText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '150%',
    color: '#ccc',
    fontFamily: 'lato',
    minHeight: '200px'
  }
}));
