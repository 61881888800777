import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { GaugeChart } from './GaugeChart';
import { IUnitThresholds } from '../../../interfaces/units';

export const ThresholdCharts = ({
  thresholds: {
    accelerationUpperThreshold,
    rotationMotorCurrentUpperThreshold,
    squeezeMotorCurrentUpperThreshold,
    instantaneousInputVoltageUpperThreshold,
    instantaneousInputVoltageLowerThreshold,
    instantaneousTemperatureUpperThreshold,
    instantaneousTemperatureLowerThreshold,
    bumpAndGoErrorTemperatureLowerThreshold,
    bumpAndGoErrorTemperatureUpperThreshold
  }
}: {
  thresholds: IUnitThresholds;
}) : ReactElement => {
  const classes = makeStyles(() => ({
    root: {
      background: '#ffffff'
    },
    chart: {
      width: '180px'
    }
  }))();

  const instantTemp = [
    { val: instantaneousTemperatureUpperThreshold, direction: 'up' },
    { val: instantaneousTemperatureLowerThreshold, direction: 'down' }
  ];
  const instantVolt = [
    { val: instantaneousInputVoltageUpperThreshold, direction: 'up' },
    { val: instantaneousInputVoltageLowerThreshold, direction: 'down' }
  ];
  const rotationMotor = [{ val: rotationMotorCurrentUpperThreshold, direction: 'up' }];
  const squeezeMotor = [{ val: squeezeMotorCurrentUpperThreshold, direction: 'up' }];
  const acceleration = [{ val: accelerationUpperThreshold, direction: 'up' }];
  const bumpAndGo = [
    { val: bumpAndGoErrorTemperatureUpperThreshold, direction: 'up' },
    { val: bumpAndGoErrorTemperatureLowerThreshold, direction: 'down' }
  ];
  return (
    <Grid container className={classes.root} data-testid="debug-threshold-charts">
      <Grid item className={classes.chart} xs={4}>
        <GaugeChart height={150} title="Instantaneous Temp" data={instantTemp} />
      </Grid>
      <Grid item className={classes.chart} xs={4}>
        <GaugeChart height={150} title="Rotation Motor Current" data={rotationMotor} />
      </Grid>
      <Grid item className={classes.chart} xs={4}>
        <GaugeChart height={150} title="Squeeze Motor Current" data={squeezeMotor} />
      </Grid>
      <Grid item className={classes.chart} xs={4}>
        <GaugeChart height={150} title="Instantaneous Voltage" data={instantVolt} />
      </Grid>
      <Grid item className={classes.chart} xs={4}>
        <GaugeChart height={150} title="Bump and Go" data={bumpAndGo} />
      </Grid>
      <Grid item className={classes.chart} xs={4}>
        <GaugeChart height={150} title="Acceleration" data={acceleration} />
      </Grid>
    </Grid>
  );
};
