import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useStyles } from '../sectionStyle';
import { FilterRow } from '../FilterRow';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import EnhancedTable from '../Table/EnhancedTable';
import { Backdrop, Button, CircularProgress, Grid, TableCell, TableRow } from '@material-ui/core';
import { EditOutlined as EditIcon, DeleteOutline as DeleteIcon } from '@material-ui/icons';
import PageDataConfig from '../PageDataConfig';
import { ITableColumn } from '../../interfaces/tables';
import { getUsers } from '../../endpoints/users';
import { propertiesSummary } from '../../endpoints/properties/properties';
import { useHistory, useLocation } from 'react-router-dom';
import usePaginatedData from '../Table/usePaginatedData';
import usePaginationProps from '../Table/usePaginationProps';
import PlaceholderedValue from '../PlaceholderedValue';
import queryString from 'query-string';
import Can from '../../Can';
import DeleteUser from './DeleteUser';

const Users = () : ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const initialPropertyFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.property && !Array.isArray(queryParams.property)) {
        return queryParams.property;
      }
    }
    return '';
  };

  const [propertyFilter, setPropertyFilter] = useState(initialPropertyFilter);

  const filters = useMemo( () => {
    const filtersObj : {[key:string]:string} = {};
    if (propertyFilter) {
      filtersObj.property = propertyFilter;
    }
    return filtersObj;
  }, [propertyFilter]);


  const paginationProps = usePaginationProps(getUsers, { filters, sortColumn: 'lastName' });
  const usersRequest = usePaginatedData(paginationProps);

  const columns : Array<ITableColumn> = useMemo( () => ([
    {
      id: 'name', label: 'Name', numeric: false, sortable: true, disablePadding: true
    },
    {
      id: 'email', label: 'Email', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'group', label: 'Group', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'role', label: 'Role', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'actions', label: 'Actions', numeric: false, sortable: false, disablePadding: true
    }
  ]), []);

  const userRows = useMemo( () => {
    return usersRequest.items.map( (user) => (
      <TableRow
        hover
        tabIndex={-1}
        key={user.id}
      >
        <TableCell size="small" align="center" key={'name'}>
          <PlaceholderedValue
            value={`${String(user.firstName)} ${String(user.lastName)}`}
            condition={() => Boolean(user.firstName || user.lastName)} />
        </TableCell>
        <TableCell size="small" align="center" key={'email'}>
          <PlaceholderedValue value={user.email} />
        </TableCell>
        <TableCell size="small" align="center" key={'group'}>
          <PlaceholderedValue value={user.experienceGroup} />
        </TableCell>
        <TableCell size="small" align="center" key={'role'}>
          <PlaceholderedValue value={user.role} />
        </TableCell>
        <TableCell size="small" align="center" key={'actions'}>
          <Can perform="users:edit" yes={() => <EditIcon className={classes.subtleActionItem} onClick={() => history.push(`/user/${String(user.id)}/edit`) } />} />
          <Can perform="units:delete" yes={() => (
            <DeleteUser success={ () => usersRequest.refetch() } user={user}>
              { ({ triggerDelete }) => <DeleteIcon aria-label="Delete" className={classes.subtleActionItem} onClick={triggerDelete} />}
            </DeleteUser>
          )}
          />

        </TableCell>
      </TableRow>
    ));
  }, [classes.subtleActionItem, usersRequest]);


  const handleFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setPropertyFilter(event.target.value);
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="users-backdrop" open={Boolean(usersRequest.status === 'loading' || usersRequest.status === 'error')} className={classes.backdrop}>
        {usersRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        {usersRequest.status === 'error' && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Users" />
      <Can
        perform="users:add"
        yes={ () => (
          <div className={classes.sectionSubmenu}>
            <Button fullWidth={false} className={classes.borderedButton} type="button" onClick={() => {
              history.push('/users/add');
            }}>
              Add User
            </Button>
          </div>
        )} />
      <Can perform="properties" yes={() => (
        <div className={classes.filterRow}>
          <FilterRow onChange={handleFilterChange} selected={propertyFilter}>
            <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} />
          </FilterRow>
        </div>)} />
      <div className={classes.table}>
        <EnhancedTable
          columns={columns}
          totalCount={usersRequest.totalCount}
          {...paginationProps}
          hideControlColumn
        >
          {userRows}
        </EnhancedTable>
      </div>
    </Grid>
  );
};

Users.propTypes = {};

export { Users };
