import { makeStyles } from '@material-ui/core';

const drawerWidth = '10vw';

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'grid',
      minHeight: '100vh',
      overflow: 'none',
      scrollbarWidth: 'none',
      gridGap: '0px',
      gridTemplateColumns: '10vw auto',
      gridTemplateRows: '60px auto',
      gridTemplateAreas: `
          "sidebar header"
          "sidebar content"`
    },
    sidebar: {
      gridArea: 'sidebar'
    },
    header: {
      gridArea: 'header',
      position: 'sticky',
      top: '0',
      zIndex: 1
    },
    content: {
      'gridArea': 'content',
      'overflowY': 'scroll',
      'padding': '1rem',
      'width': 'calc( 100% - 2rem )',
      '&::-webkit-scrollbar': {
        width: '0'
      }
    },
    rootContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      margin: '0px',
      padding: '0px'
    },
    containerTopbar: {
      width: '100%',
      height: '100%',
      padding: '0px'
    },
    overview: {
      flexGrow: 1
    },

    contentContainer: {
      width: '100%'
    },
    contentTopBar: {
      width: '100%'
    },
    contentItem: {
      width: '100%',
      margin: '0px',
      padding: '0px'
    },
    gridContainer: {
      justifyContent: 'center',
      background: '{ theme.backgroundColor }'
    },
    drawer: {
      flexShrink: 0,
      height: '100%',
      margin: '0'
    },
    drawerLogo: {
      width: '80%',
      height: '80%',
      alignSelf: 'center',
      margin: '5px'
    },
    drawerListItem: {
      backgroundColor: 'rgb(9,54,152)',
      cursor: 'pointer',
      color: 'white'
    },
    drawerListItemSelected: {
      backgroundColor: theme.palette.secondary.dark,
      cursor: 'pointer',
      color: 'white',
      borderTop: '1px solid #345bc7',
      borderBottom: '1px solid #345bc7'
    },
    drawerLogoContainer: {
      width: drawerWidth,
      padding: '10px',
      background: 'unset'
    },
    drawerPaper: {
      backgroundColor: 'rgb(9,54,152)',
      height: '100%'
    },
    typography: {
      margin: 'auto',
      color: 'white'
    },
    block: {
      height: '300px',
      width: '300px',
      backgroundColor: 'red'
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'space-around',
      width: '95%'
    },
    appBar: {
      background: 'white',
      width: '100%',
      height: '60px',
      color: 'black',
      position: 'sticky'
    },
    appBarTitle: {
      fontWeight: 600
    },
    appBarNotifications: {
      display: 'flex',
      marginLeft: theme.spacing(14)
    },
    searchContainer: {
      position: 'relative'
    },
    searchResults: {
      background: 'white',
      padding: '.5rem',
      paddingTop: '1.5rem',
      right: 0,
      left: '100px',
      top: '37px',
      position: 'absolute'
    },
    searchResultsVisible: {
    },
    searchResultMessage: {
      fontStyle: 'italic'
    },
    search: {
      position: 'relative',
      borderRadius: 'none',
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(14),
      border: '1px solid black'
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    inputRoot: {
      color: 'inherit'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch'
      }
    },
    appBarRightSide: {
      position: 'absolute',
      right: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    appBarListItem: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2)
    },
    appBarListItemTitle: {
      fontWeight: 200
    },
    appBarButtonText: {
      margin: 'unset',
      padding: 'unset'
    },
    appBarListItemFinal: {
      marginRight: '0px',
      cursor: 'pointer'
    },
    appBarListItemPush: {
      marginRight: 'auto'
    },
    loginContainer: {
      display: 'flex',
      height: '110%',
      background: 'white',
      alignContent: 'center',
      alignItems: 'center',
      margin: '-1%',
      overflow: 'none'
    },
    loginForm: {
      marginLeft: '30%',
      marginBottom: '10%',
      alignSelf: 'center',
      background: 'white',
      alignContent: 'baseline',
      width: 'fit-content'
    },
    loginLogo: {
      width: '300px',
      marginBottom: '10px'
    },
    loginInput: {
      border: '1px gray solid',
      padding: '5px'
    },
    loginLabel: {
      paddingLeft: '5px',
      paddingTop: '3px'
    },
    loginInputFormControl: {
      margin: '5px'
    },
    loginButton: {
      alignSelf: 'flex-end',
      textTransform: 'none'
    },
    errorText: {
      color: theme.palette.error.main,
      fontSize: '90%',
      fontFamily: 'Lato',
      marginTop: '.5rem'
    },
    loginResetPasswordButton: {}
  }),
  { name: 'HookGlobalStyles', index: 0 }
);
