/* eslint-disable quotes */
import React, { ReactElement, useEffect } from "react";
import usePaginatedData from './Table/usePaginatedData';
import usePaginationProps from './Table/usePaginationProps';

type paramTypes = {
  optionsPath: string;
  name: string;
  statusCallback?: (val:string, val2:Array<any>) => any;
  opts?: any;
}

const FilterOptionsFromApi = ( { optionsPath, name, statusCallback, opts } : paramTypes ) : ReactElement => {

  const paginationProps = usePaginationProps(optionsPath, opts || {});
  const optionsRequest = usePaginatedData(paginationProps);

  useEffect( () => {
    if (statusCallback) {
      statusCallback(optionsRequest.status, optionsRequest.items);
    }
  }, [optionsRequest, statusCallback]);

  // useEffect( () => {
  //   paginationProps.setEndpoint(optionsPath);
  // }, [optionsPath, paginationProps]);

  return (
    <>
      <option value="">{name}</option>
      {
        optionsRequest.status === 'success' && optionsRequest.items.map( (item) => (
          <option value={item.id}>{item.name}</option>
        ))
      }
    </>
  );
};

export { FilterOptionsFromApi };
