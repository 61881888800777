/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, ReactElement, useEffect } from 'react';
import { Button, FormControl, Grid, Backdrop, CircularProgress, InputLabel, Input, FormHelperText, Select  } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { useStyles } from '../sectionStyle';
import { useForm } from 'react-hook-form';
import { IRequestStatus } from '../../interfaces/util';
import ApiWrapper from '../../ApiWrapper';
import { useHistory, useParams } from 'react-router-dom';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { IUnitPatch } from '../../interfaces/units';
import Can from '../../Can';
import { vehicleSummary } from '../../endpoints/vehicles/vehicles';
import { propertiesSummary } from '../../endpoints/properties/properties';
import { patchUnit } from '../../endpoints/units';

interface ParamTypes {
  id: string
}

const EditVehicle = () : React.ReactElement => {
  const { id } = useParams<ParamTypes>();
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [updateRequestStatus, setUpdateRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [preexistingVehicle, setPreexistingVehicle] = useState('');
  const [vehicleRequestStatus, setVehicleRequestStatus] = useState('init');
  const [propertyRequestStatus, setPropertyRequestStatus] = useState('init');
  const [vehicleList, setVehicleList] = useState('');
  const [modalDismissed, setModalDismissed] = useState(true);
  const history = useHistory();

  const dismissModal = () => {
    setModalDismissed(true);
  };

  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );
  const { register, handleSubmit, setValue, errors } = useForm<IUnitPatch>();
  //TODO: fix any type issue.
  const propertySelectChange = (event: any) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const option =  optionElement.getAttribute('value');
    if ( option.length > 0) {
      setVehicleList(option);
    } else {
      setVehicleList('');
    }
  };

  useEffect( () => {
    const fetchData = async () => {
      setModalDismissed(false);
      setRequestStatus({ loading: true, success: false, error: false });
      const response = await ApiWrapper({
        url: patchUnit(id),
        method: 'GET'
      });
      setRequestStatus({ loading: false, success: true, error: false });

      const { name, vehicleId, machineSN, propertyId } = response.data;
      setValue('name', name);
      setValue('vehicleId', vehicleId);
      setValue('machineSN', machineSN);
      setValue('propertyId', propertyId);
      setVehicleList(propertyId);
      setPreexistingVehicle(vehicleId);
      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => dismissModal);
      setModalDismissed(true);
    };

    fetchData().catch( (error) => {
      setRequestStatus({ loading: false, success: false, error: true });
      setModalAction( () => () => {
        history.push('/units');
        return null;
      } );
      console.error(error);
    });
  }, [history, id, setValue]);

  const onSubmit = async (data: IUnitPatch): Promise<void> => {
    const formattedData : IUnitPatch = {
      vehicleId: data.vehicleId,
      machineSN: data.machineSN,
      propertyId: data.propertyId
    };
    setModalDismissed(false);

    try {
      setUpdateRequestStatus({ loading: true, success: false, error: false });
      await ApiWrapper({
        url: patchUnit(id),
        method: 'PATCH',
        data: formattedData
      });
      setUpdateRequestStatus({ loading: false, success: true, error: false });

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => () => {
        history.push('/units');
        return null;
      } );
    } catch (error) {
      setUpdateRequestStatus({ loading: false, success: false, error: true });
      console.error(error);
    }
  };

  useEffect( () => {
    setValue('vehicleId', preexistingVehicle);
  }, [vehicleRequestStatus, preexistingVehicle, setValue]);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="edit-unit-backdrop" onClick={modalAction} open={!modalDismissed} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {( requestStatus.error || updateRequestStatus.error ) && (<span className={classes.loadingError}>Could not load. Please refresh and try again.</span>)}
        {updateRequestStatus.success && (<span className={classes.loadingError}>Unit updated successfully.</span>)}
      </Backdrop>
      <PageDataConfig title="Edit Unit" />
      <form className={classes.form} onSubmit={ handleSubmit(onSubmit)} >
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="propertyId" shrink>Property</InputLabel>
          <Select
            id="propertyId"
            name="propertyId"
            native
            inputRef={register({ required: true })}
            error={Boolean(errors.propertyId)}
            className={classes.formInput}
            data-status={propertyRequestStatus}
            onChange={propertySelectChange}
          >
            <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} statusCallback={setPropertyRequestStatus} />
          </Select>
          <FormHelperText>{errors.propertyId ? 'Property must be selected' : ''}</FormHelperText>
        </FormControl>

        <Can perform="vehicles" yes={() => (
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="vehicleId" shrink>Vehicle</InputLabel>
            <Select
              id="vehicleId"
              name="vehicleId"
              data-testid="vehicle-options"
              native
              inputRef={register({ required: true })}
              error={Boolean(errors.vehicleId)}
              className={classes.formInput}
              data-status={vehicleRequestStatus}
            >
              <FilterOptionsFromApi name="Vehicle" optionsPath={vehicleList? vehicleSummary(vehicleList) : ''} statusCallback={setVehicleRequestStatus}/>
            </Select>
            <FormHelperText>{errors.vehicleId ? 'Vehicle must be selected' : ''}</FormHelperText>
          </FormControl>
        )} />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="name" shrink>Name</InputLabel>
          <Input
            id="name"
            name="name"
            inputRef={register({ required: true, minLength: 3 })}
            disabled
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="machineSN" shrink>Machine S/N</InputLabel>
          <Input
            id="machineSN"
            name="machineSN"
            inputRef={register({ minLength: 1 })}
            error={Boolean(errors.machineSN)}
            className={classes.formInput}
          />
        </FormControl>
        <Button type="submit" className={classes.borderedButton}>Save</Button>
      </form>
    </Grid>
  );
};

export default EditVehicle;
