import React, { ReactElement } from 'react';
import Moment from 'react-moment';
import cx from 'classnames';
import { useStyles } from './style';
import { unitData } from '../../../interfaces/units';

interface IMapMetaProps {
  deviceData: unitData
}
export const MapMeta = ({ deviceData }: IMapMetaProps) : ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.mapMeta}>
      <div className={classes.mapMetaItem}>
        {!!deviceData.metadata && (
          <div>
            <p>Location as of</p>
            <p className={classes.emphasis}>
              <Moment format="M/D/YYYY HH:mm">{deviceData.metadata.compositeStateAt}</Moment>
            </p>
          </div>
        )}
      </div>
      <div className={cx(classes.mapMetaItem, classes.darkMeta)}>
        {!!deviceData.metadata && !!deviceData.metadata.address && (
          <div>
            <p>{deviceData.metadata.address.street1}</p>
            <p className={classes.emphasis}>
              {deviceData.metadata.address.city}
              ,
              {' '}
              {deviceData.metadata.address.state}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
