import React, { useMemo, ReactElement } from 'react';
import { useStyles } from '../sectionStyle';
import EnhancedTable from '../Table/EnhancedTable';
import { Button, TableCell, TableRow } from '@material-ui/core';
import Moment from 'react-moment';
import AcknowledgeAlert from './AcknowledgeAlert';
import Can from '../../Can';
import AlertIcon from './AlertIcon';
import { IAlert } from '../../interfaces/alerts';
import { IUsePaginationProps } from '../../interfaces/tables/';
import cn from 'classnames';

type props = {
  items: IAlert[];
  totalCount: number;
  refetch: () => void;
  paginationProps: IUsePaginationProps;
  handleRowClick?: (alert:IAlert) => void;
}

const AlertsTable = ({ items, totalCount, refetch, paginationProps, handleRowClick } : props) : ReactElement => {

  const classes = useStyles();

  const columns = [
    { id: 'unitId', numeric: true, disablePadding: true, label: 'Serial Number' },
    { id: 'machineSN', numeric: false, disablePadding: false, label: 'Machine S/N' },
    { id: 'time', numeric: true, disablePadding: false, label: 'Error Code Time' },
    { id: 'level', numeric: true, disablePadding: false, label: 'Error Level' },
    { id: 'name', numeric: false, disablePadding: false, label: 'Error Name' },
    { id: 'message', numeric: true, disablePadding: false, label: 'Description' }
  ];

  const friendlyLevelName = (backendName: 'info' | 'warning' | 'error' | 'critical') => ({
    info: 'Info',
    warning: 'Warning',
    error: 'Service Required',
    critical: 'Service Required'
  })[backendName];

  const rows = useMemo( () => {
    return items.map((row: IAlert, index: number) => {
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <TableRow
          hover
          tabIndex={-1}
          key={row.id}
          onClick={ () => {
            if (handleRowClick) {
              handleRowClick(row);
            }
          }
          }
          className={cn({
            [classes.noHoverRow]: !handleRowClick
          })}
        >
          <TableCell align="left" id={labelId} scope="row">
            {row.deviceName}
          </TableCell>
          <TableCell size="small" align="left">
            {row.machineSN}
          </TableCell>
          <TableCell size="small" align="left">
            <Moment unix>{row.creationDateMS / 1000}</Moment>
          </TableCell>
          <TableCell size="small" align="left">
            <AlertIcon level={row.level} /><span className={classes.alertTableName}>{friendlyLevelName(row.level)}</span>
          </TableCell>
          <TableCell size="small" align="left">
            {row.subject}
          </TableCell>
          <TableCell size="small" align="left">
            {row.message}
          </TableCell>
          <TableCell>
            {row.state === 'new' && (
              <Can perform="alerts:acknowledge"
                yes={() => (
                  <AcknowledgeAlert alert={row} success={() => {
                    refetch();
                  } }>
                    {( { acknowledge } ) => {
                      return (<Button className={classes.borderedButton} onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        acknowledge();
                      }}>Acknowledge</Button>);
                    }}
                  </AcknowledgeAlert>
                )}
              /> )}
            {row.state === 'acknowledged' && <span>Acknowledged</span> }
          </TableCell>
        </TableRow>
      );
    });
  }, [items, classes.noHoverRow, classes.alertTableName, classes.borderedButton, handleRowClick, refetch]);

  return (
    <div className={classes.table}>
      <EnhancedTable
        columns={columns}
        totalCount={totalCount}
        {...paginationProps}
        hideControlColumn
      >
        {rows}
      </EnhancedTable>
    </div>
  );
};

export default AlertsTable;
