import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IAlertIconProps } from '../../interfaces/alerts';

export default function AlertIcon(props: IAlertIconProps) : ReactElement {
  const alertBadge = (level: string) : IconProp => {
    const iconNames: any = {
      warning: 'exclamation-triangle',
      error: 'exclamation-circle',
      info: 'bell',
      critical: 'exclamation-circle'
    };
    return iconNames[level] || alertBadge('info');
  };

  const alertColor = (level: string): string => {
    const iconNames: any = {
      warning: '#ff9900',
      error: '#ff0000',
      info: '#099aff',
      critical: '#ff0000'
    };
    return iconNames[level] || alertColor('info');
  };

  const { level, size } = props;

  const iconName = alertBadge(level);
  const iconColor = alertColor(level);

  return (
    <FontAwesomeIcon icon={iconName} color={iconColor} size={size} />
  );
}
