import { setPageName, setSiteName } from './types/index';

type TPageData = {
  pageName: string;
  siteName: string;
}
type Action = {
  type: string;
  payload?: any;
};

export default function pageDataReducer(state: TPageData, { type, payload }: Action) : TPageData {
  switch (type) {
    case setPageName:
      return { ...state, pageName: payload.pageName };
    case setSiteName:
      return { ...state, siteName: payload.siteName };
    default:
      return state;
  }
}
