import React, { ReactElement, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Select, MenuItem, Button } from '@material-ui/core';
import { DateRange } from '@material-ui/icons';
import { timeRange } from '../../../interfaces/units';
import 'react-datepicker/dist/react-datepicker.css';

type TFetchData = (timeRange?: timeRange) => void;
interface IDateRangePickerProps {
  onSubmit: TFetchData
}

const useStyles = makeStyles(() => ({
  root: {
    'display': 'flex',
    'flexWrap': 'wrap',
    'marginBottom': 10,
    'backgroundColor': '#fff',
    'alignItems': 'center',
    'justifyContent': 'center',
    '& > *': {
      margin: 10
    }
  },
  select: {
    width: 125
  },
  dateRangeBox: {
    'display': 'flex',
    'padding': '5px 10px 5px 5px',
    'flexWrap': 'wrap',
    'fontFamily': 'Lato',
    'backgroundColor': '#fff',
    'alignItems': 'center',
    'border': '1px solid #ccc',
    '& > *': {
      margin: 5
    }
  },
  datePicker: {
    width: 125,
    height: 25,
    border: '1px solid #ccc',
    textAlign: 'center'
  },
  button: {

  }
}));

export const DateRangePicker = ({ onSubmit }: IDateRangePickerProps) : ReactElement => {
  const now = Date.now();
  const [preSelectedRange, setPreSelectedRange] = useState('24-hours');
  const [start, setStart] = useState(now - 86400000);
  const [end, setEnd] = useState(now);
  const [resolution, setResolution] = useState(300000);

  const classes = useStyles();

  const updateParent = () => {
    onSubmit({
      start,
      end,
      resolution
    });
  };

  const updateStartAndEnd = (v: string): void => {
    const options: {[key:string]: () => void} = {
      '1-week': () => {
        setStart(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).valueOf());
        setEnd(new Date().valueOf());
      },
      '24-hours': () => {
        setStart(new Date(new Date().getTime() - 24 * 60 * 60 * 1000).valueOf());
        setEnd(new Date().valueOf());
      },
      '12-hours': () => {
        setStart(new Date(new Date().getTime() - 12 * 60 * 60 * 1000).valueOf());
        setEnd(new Date().valueOf());
      },
      '1-hour': () => {
        setStart(new Date(new Date().getTime() - 1 * 60 * 60 * 1000).valueOf());
        setEnd(new Date().valueOf());
      }
    };
    options[v]();
  };

  useEffect(() => {
    if (preSelectedRange) {
      updateStartAndEnd(preSelectedRange);
    }
  }, [preSelectedRange]);

  const reasonableResolutionInMS = (durationInS: number): number => {
    if (durationInS > 2592000) { // month +
      return 21600000; // 6 hours
    }
    if (durationInS > 604800) { // week +
      return 1800000; // 30 mins
    }
    if (durationInS > 86400) { // day +
      return 300000; // 5 mins
    }
    return 30000; // 30 seconds
  };

  useEffect(() => {
    const durationInS = (end - start) / 1000;
    const newResolution = reasonableResolutionInMS(durationInS);
    setResolution(newResolution);
  }, [start, end]);

  return (
    <Card className={classes.root}>
      <Select
        labelId="preselected-ranges-datepicker-label"
        id="preselected-ranges-datepicker"
        className={classes.select}
        displayEmpty
        value={preSelectedRange}
        onChange={(e: React.ChangeEvent<any>) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setPreSelectedRange(e.target.value);
        }}
      >
        <MenuItem value="">Custom Range</MenuItem>
        <MenuItem value="1-hour">Past hour</MenuItem>
        <MenuItem value="12-hours">Past 12 hours</MenuItem>
        <MenuItem value="24-hours">Past 24 hours</MenuItem>
        <MenuItem value="1-week">Past week</MenuItem>
      </Select>
      <div className={classes.dateRangeBox}>
        <DateRange htmlColor="#777" />
        From
        <DatePicker
          selected={new Date(start)}
          className={classes.datePicker}
          onChange={(date: Date) => {
            if (!date) {
              return;
            }
            setStart(date.valueOf());
            setPreSelectedRange('');
          }}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          maxDate={new Date(end) || new Date()}
          placeholderText="Start Date"
          dateFormat="MM/dd/yy h:mm aa"
          timeCaption="time"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport'
            }
          }}
        />
        to
        <DatePicker
          selected={new Date(end)}
          className={classes.datePicker}
          onChange={(date: Date) => {
            if (!date) {
              return;
            }
            setEnd(date.valueOf());
            setPreSelectedRange('');
          }}
          showTimeSelect
          timeFormat="HH:mm"
          placeholderText="End Date"
          minDate={
            new Date(start) || null
          }
          maxDate={new Date()}
          timeIntervals={15}
          dateFormat="MM/dd/yy h:mm aa"
          timeCaption="time"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: 'viewport'
            }
          }}
        />
      </div>
      <Button onClick={updateParent}>Update</Button>
    </Card>
  );
};
