// eslint-disable @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router';
import { Card, Grid, CardHeader, Backdrop, CircularProgress } from '@material-ui/core';
import { AxiosPromise } from 'axios';
import cx from 'classnames';
import { useStyles } from '../Debug/style';
import ApiWrapper from '../../../ApiWrapper';
import { getUnitMetadata } from '../../../endpoints/units';
import { getAlerts } from '../../../endpoints/alerts';
import { unitData, IUnitMetadataResponse } from '../../../interfaces/units';
import { IRequestStatus } from '../../../interfaces/util';
import { GaugeChart } from '../Debug/GaugeChart';
import { LocationMap } from '../LocationMap';
import { MapMeta } from '../Debug/MapMeta';
import { Header } from './Header';
import AlertsTable from '../../Alerts/AlertsTable';
import { Marker } from 'react-google-maps';
import MarkerNormal from '../../../assets/marker--normal.svg';
import usePaginationProps from '../../Table/usePaginationProps';
import usePaginatedData from '../../Table/usePaginatedData';
import PageDataConfig from '../../PageDataConfig';

interface ParamTypes {
  id: string
}

const Unit = () : ReactElement => {
  const classes = useStyles();
  const { id } = useParams<ParamTypes>();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [deviceData, setDeviceData] = useState<unitData>({});

  const filters = {
    unitIds: id
  };

  const alertsPaginationProps = usePaginationProps(getAlerts, { sortColumn: 'creationDate', sortDirection: 'desc', filters });
  const alertsPaginatedData = usePaginatedData(alertsPaginationProps);

  const fetchData = useCallback(async () => {

    setRequestStatus({ loading: true });

    try {
      const metadataPromise: AxiosPromise<IUnitMetadataResponse> = ApiWrapper({
        url: getUnitMetadata(id),
        method: 'GET',
        params: { at: new Date().valueOf() }
      });

      const response = await Promise.all([metadataPromise]);
      setRequestStatus({ loading: false, success: true, error: false });
      setDeviceData({ metadata: response[0].data });
    } catch (e) {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error('Error Fetching Device Data:', e);
    }
  }, [id]);

  /* Initial Load */
  useEffect(() => {
    fetchData().catch( (e) => {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error('Could not load debug data', e);
    });
  }, [fetchData]);

  return (
    <div className={classes.debugRoot}>
      <Backdrop data-testid="unit-backdrop" open={Boolean(requestStatus.loading || requestStatus.error)} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {requestStatus.error && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}

      </Backdrop>
      <PageDataConfig title="Unit" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Header
            name={deviceData.metadata ? deviceData.metadata.name : ''}
            connected={deviceData.metadata ? deviceData.metadata.connected : null}
            machineSN={deviceData.metadata ? deviceData.metadata.machineSN : ''}
            property={deviceData.metadata ? deviceData.metadata.propertyName : ''}
            vehicle={deviceData.metadata ? deviceData.metadata.vehicle : ''}
          />
        </Grid>
        <Grid item container xs={12} className={classes.leftCardGroup}>
          <Grid item xs={8} className={classes.errorGrid}>
            <Card className={cx(classes.card, classes.errorCard)}>
              <GaugeChart
                testId="unit-error-block"
                title="Error Code"
                data={[{ val: deviceData.metadata ? deviceData.metadata.errorCodeDescription : '' }]}
                color={deviceData.metadata && deviceData.metadata.errorCodeDescription ? '#fff' : undefined}
                backgroundColor={
                  deviceData.metadata && deviceData.metadata.errorCodeDescription ? '#f45252' : undefined
                }
              />
            </Card>
          </Grid>
          <Grid item xs={4} className={classes.cycleGrid}>
            <Card className={cx(classes.card, classes.cycleCard)}>
              <GaugeChart
                testId="unit-cycle-block"
                title="Cycle Count"
                data={[{ val: deviceData.metadata && deviceData.metadata.cycleCount ? deviceData.metadata.cycleCount.toString() : '' }]}
              />
            </Card>
          </Grid>
          <Grid item xs={12} className={classes.busTrackingGrid}>
            <Card className={cx(classes.card)}>
              <Grid container>
                <Grid item xs={8}>
                  <LocationMap>
                    { deviceData.metadata && deviceData.metadata.latitude && deviceData.metadata.longitude && (
                      <Marker
                        position={{
                          lat: deviceData.metadata.latitude,
                          lng: deviceData.metadata.longitude
                        }}
                        icon={{
                          url: MarkerNormal
                        }}
                      />
                    )}
                  </LocationMap>
                </Grid>
                <Grid item xs={4}>
                  <MapMeta deviceData={deviceData} />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <CardHeader className={classes.cardHeader} title="Device Alerts" />
          <AlertsTable
            items={alertsPaginatedData.items}
            totalCount={alertsPaginatedData.totalCount}
            paginationProps={alertsPaginationProps}
            refetch={alertsPaginatedData.refetch}
          />
        </Grid>
      </Grid>

    </div>
  );
};

export default Unit;
