import React, { useEffect, useRef, useState } from 'react';
import ApiWrapper from '../../ApiWrapper';
import { IUser } from '../../interfaces/users';
import { deleteUser as deleteUserEndpoint } from '../../endpoints/users';
import Confirm from '../Confirm';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from '../sectionStyle';
import PlaceholderedValue from '../PlaceholderedValue';

type TChildProps = {
  triggerDelete: () => void
}

type TChild = (props : TChildProps) => React.ReactChild | React.ReactChild[];

type TProps = {
  user: IUser;
  children: TChild;
  success?: () => void;
}
const DeleteUser = ({ user, children, success } : TProps) : React.ReactElement => {

  const classes = useStyles();

  // When user clicks acknowledge, IAlert object put in staging space for user to confirm
  const [deleteUserStaging, setDeleteUserStaging ] = useState<null | IUser>(null);
  const [deleteUserNetworkRequest, setDeleteUserNetworkRequest] = useState({ status: 'init' });

  // Track if not mounted so state isnt updated on non-existent component
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const stageDeleteUser = () => {
    setDeleteUserStaging(user);
  };

  const deleteUser = async () => {
    if (!deleteUserStaging) {
      return;
    }
    try {
      setDeleteUserNetworkRequest({ status: 'loading' });
      await ApiWrapper({
        url: deleteUserEndpoint(deleteUserStaging.id),
        method: 'DELETE'
      });

      if (isMounted.current) {
        setDeleteUserNetworkRequest({ status: 'success' });
        setDeleteUserStaging(null);
        if (success) {
          success();
        }
      }
    } catch (e) {
      if (isMounted.current) {
        setDeleteUserNetworkRequest({ status: 'error' });
        setDeleteUserStaging(null);
      }
      console.error('Error deleting user:', e);
    }
  };

  const showLoadingBackdrop = deleteUserNetworkRequest.status === 'loading' || deleteUserNetworkRequest.status === 'error';

  return (
    <>
      <Backdrop data-testid="acknowledge-alert-debug-backdrop" open={showLoadingBackdrop} className={classes.backdrop}>
        { deleteUserNetworkRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        { deleteUserNetworkRequest.status === 'error' && (<span className={classes.loadingError}>Could not delete user. Please refresh and try again.</span>)}
      </Backdrop>
      {deleteUserStaging && (
        <Confirm
          no={ () => setDeleteUserStaging(null) }
          yes={ deleteUser }
          yesText="Confirm"
        >
          <h1>
            <PlaceholderedValue
              value={`${String(user.firstName)} ${String(user.lastName)}`}
              condition={() => Boolean(user.firstName || user.lastName)} />
          </h1>
          <h2><PlaceholderedValue value={user.email} /></h2>
          <div>Are you sure you want to delete this user?</div>
        </Confirm>
      )}
      { children && children({ triggerDelete: stageDeleteUser }) }
    </>);
};

export default DeleteUser;

