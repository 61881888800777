import React, { useState, useContext, MouseEvent, ReactElement } from 'react';
import { Typography, AppBar, Toolbar, Menu, MenuItem, Button } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useStyles } from '../material-theme-styles/default';
import usePageData from '../state-manipulation/hooks/usePageData';
import Search from './Search';
import useLogout from './useLogout';

export const Topbar = () : ReactElement => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const classes = useStyles();
  const [pageData] = usePageData();
  const logout = useLogout();

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    // const { value } = event.currentTarget;
    const value = event.currentTarget;
    if (value) {
      setAnchorEl(value);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography className={classes.appBarTitle} variant="h6">
          {pageData.pageName}
        </Typography>
        <Search />
        <div className={classes.appBarRightSide}>
          <Typography
            className={classes.appBarListItemFinal}
            variant="h6"
            onClick={logout}
          >
            Logout
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};
