import React, { ReactElement, useCallback, useMemo } from 'react';
import { useStyles } from '../sectionStyle';
import EnhancedTable from '../Table/EnhancedTable';
import { Backdrop, CircularProgress, Grid, TableCell, TableRow } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { ITableColumn } from '../../interfaces/tables';
import { getRegions } from '../../endpoints/regions/regions';
import { IRegion } from '../../interfaces/regions';
import { useHistory } from 'react-router-dom';
import usePaginatedData from '../Table/usePaginatedData';
import usePaginationProps from '../Table/usePaginationProps';
import NumberFormat from 'react-number-format';

const Regions = () : ReactElement => {
  const classes = useStyles();
  const paginationProps = usePaginationProps(getRegions);
  const regionsRequest = usePaginatedData(paginationProps);
  const history = useHistory();

  const onClickHandler = useCallback((region: IRegion) => {
    history.push(`/properties/?region=${region.id}`);
    return null;
  }, [history]);

  const columns : Array<ITableColumn> = useMemo( () => ([
    {
      id: 'name', label: 'Name', numeric: false, sortable: true, disablePadding: true
    },
    {
      id: 'properties', label: '# Properties', numeric: true, sortable: false, disablePadding: true
    },
    {
      id: 'contact', label: 'Contact', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'phone', label: 'Phone', numeric: true, sortable: false, disablePadding: true
    }
  ]), []);

  const regionRows = useMemo( () => {
    return regionsRequest.items.map( (region) => (
      <TableRow
        hover
        tabIndex={-1}
        key={region.id}
        onClick={ () => onClickHandler(region) }
      >
        <TableCell size="small" align="center" key={'name'}>
          {region.name}
        </TableCell>
        <TableCell size="small" align="center" key={'properties'}>
          {region.properties.length}
        </TableCell>
        <TableCell size="small" align="center" key={'contact'}>
          {region.contact}
        </TableCell>
        <TableCell size="small" align="center" key={'phone'}>
          <NumberFormat displayType={'text'} format="+# (###) ###-####" mask="_" value={region.phone} />
        </TableCell>
      </TableRow>
    ));
  }, [regionsRequest.items, onClickHandler]);

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="debug-backdrop" open={Boolean(regionsRequest.status === 'loading' || regionsRequest.status === 'error')} className={classes.backdrop}>
        {regionsRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        {regionsRequest.status === 'error' && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Regions" />
      <div className={classes.table}>
        <EnhancedTable
          columns={columns}
          totalCount={regionsRequest.totalCount}
          {...paginationProps}
          hideControlColumn
        >
          {regionRows}
        </EnhancedTable>
      </div>
    </Grid>
  );
};

Regions.propTypes = {};

export { Regions };
