import { useContext, Dispatch } from 'react';
import { UserContext } from '../providers/UserDataProvider';
import { TUserData } from '../reducers/user/types';

type Action = {
  type: string;
  payload?: any;
};

export default function useUserData() : null | [TUserData, Dispatch<Action>] {
  const contextValue = useContext(UserContext);
  return contextValue;
}
