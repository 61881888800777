import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Button, TableCell } from '@material-ui/core';
import { useParams } from 'react-router';
import { AxiosPromise } from 'axios';
import TableRow from '@material-ui/core/TableRow';
import Moment from 'react-moment';
import ApiWrapper from '../../../ApiWrapper';
import { unitCommand } from '../../../endpoints/units';
import { IAlert } from '../../../interfaces/alerts';
import EnhancedTable from '../../Table/EnhancedTable';
import { AlertHistory } from '../../Overview/AlertHistory';
import AlertIcon from '../../Alerts/AlertIcon';
import { IUnitCommandResponse } from '../../../interfaces/units';
import cn from 'classnames';

type propTypes = {
  alerts: Array<IAlert>;
  totalAlerts: number;
  refreshDataIfDefaultRange: () => void;
};

interface ParamTypes {
  id: string
}

export const DeviceCommands = ({ alerts, totalAlerts, refreshDataIfDefaultRange } : propTypes) : ReactElement => {
  const { id } = useParams<ParamTypes>();
  const classes = makeStyles(() => ({
    card: {
      minHeight: 250
    },
    buttons: {
      borderRadius: 'unset',
      padding: '10px',
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    button: {
      'background': '#1B73FF',
      'color': '#fff',
      'width': '100%',
      'margin': '0 20px',
      '&:hover': {
        color: '#1B73FF'
      }
    },
    disabledButton: {
      background: '#ddd'
    },
    title: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      minWidth: '154px',
      padding: '5px',
      fontSize: '10pt'
    },
    alertName: {
      textTransform: 'capitalize',
      paddingLeft: '5px'
    },
    tableTotalMessage: {
      margin: '20px',
      fontFamily: 'Lato',
      fontStyle: 'italic'
    }
  }))();

  const buttons = [
    { commandType: 'canLog', label: 'Upload CAN Logs' },
    { commandType: 'requestModuleData', label: 'Query SN & Type' },
    { commandType: 'allThresholds', label: 'Force Threshold Update' },
    { commandType: '', label: 'Trigger Secure', disabled: true },
    { commandType: '', label: 'Trigger Stow', disabled: true }
  ];

  const sendCommand = async (commandType: string, commandText: string) => {
    try {
      const promise: AxiosPromise<IUnitCommandResponse> = ApiWrapper({
        url: unitCommand(id),
        method: 'POST',
        data: { commandType }
      });

      await promise;

      refreshDataIfDefaultRange();
    } catch (e) {
      console.error(`Error Sending Command ${commandText} to Device ${id}`);
    }
  };

  const getColumns = () => [
    { id: 'level', numeric: true, disablePadding: true, label: 'Level' },
    { id: 'subject', numeric: false, disablePadding: false, label: 'Subject' },
    { id: 'message', numeric: false, disablePadding: false, label: 'Message' },
    { id: 'time', numeric: false, disablePadding: false, label: 'Occured At' }
  ];

  const renderRows = () => {
    if (!alerts) {
      return <></>;
    }
    return alerts.map((row: IAlert, index: number) => {
      const labelId = `enhanced-table-checkbox-${index}`;

      return (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.creationDateMS}>
          <TableCell component="th" align="center" id={labelId} scope="row" />
          <TableCell component="th" size="small">
            <AlertIcon level={row.level} />
            <span className={classes.alertName}>{row.level}</span>
          </TableCell>
          <TableCell size="small">{row.subject}</TableCell>
          <TableCell size="small">{row.message}</TableCell>
          <TableCell size="small">
            <Moment format="M/D/YYYY HH:mm">{row.creationDateMS}</Moment>
          </TableCell>
        </TableRow>
      );
    });
  };

  const CommandButton = ({ commandType, label, disabled }: { commandType: string; label: string; disabled?: boolean }) => (
    <Button disabled={disabled} className={cn({ [classes.button]: true, [classes.disabledButton]: disabled })} key={label} onClick={disabled ? () => true : () => sendCommand(commandType, label)}>
      <span className={classes.title}>{label}</span>
    </Button>
  );

  const renderTotalEventsMessage = () => {
    if (!totalAlerts) {
      return 'No events to show for this time range.';
    }
    if (totalAlerts === 1) {
      return 'There is only 1 event for this time range.';
    }
    if (totalAlerts <= alerts.length) {
      return `Showing all ${totalAlerts} alerts for this time range.`;
    }
    return `Showing ${alerts.length} of ${totalAlerts} total alerts for this time range. `;
  };

  return (
    <Card className={classes.card}>
      <div className={classes.buttons}>{buttons.map((button) => CommandButton(button))}</div>
      <EnhancedTable
        rowsPerPage={alerts.length}
        totalCount={alerts.length}
        page={0}
        columns={getColumns()}
        rowCount={AlertHistory.length}
        hidePagination
        hideControlColumn
        handleChangePage={() => true}
        handleChangeRowsPerPage={() => true}
      >
        <>{renderRows()}</>
      </EnhancedTable>
      <div className={classes.tableTotalMessage}>{renderTotalEventsMessage()}</div>
    </Card>
  );
};
