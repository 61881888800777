/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useContext, useEffect, ReactElement } from 'react';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import {
  FormControl,
  Button,
  Input,
  InputLabel
} from '@material-ui/core';
import { useStyles } from '../material-theme-styles/default';
import { login } from '../endpoints/auth';
import { AuthContext, AuthContextInterface } from '../state-manipulation/providers/AuthWrapper';
import ApiWrapper from '../ApiWrapper';
import useUserData from '../state-manipulation/hooks/useUserData';
import { updateEmail, setFirstLogin, updateName, setIsAdmin, setAccessRules } from '../state-manipulation/reducers/user/types';

const Login = () : ReactElement => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const userData = useUserData();
  const userDispatch = userData ? userData[1] : () => true;
  const [erroredResponse, setErroredResponse] = useState(false);

  const history = useHistory();
  const { isAuthenticated, setIsAuthenticated } = useContext<AuthContextInterface>(AuthContext);
  const classes = useStyles();

  const handleResetPasswordClick = () => {
    window.location.assign('/forgot-password');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    // prevent enter from submitting the form
    event.preventDefault();

    const data = {
      email,
      password
    };

    try {
      const response: AxiosResponse = await ApiWrapper({
        url: login,
        method: 'POST',
        data
      });

      if (!response || !response.data || !response.data.loggedIn) {
        setErroredResponse(true);
        setIsAuthenticated(false);
        return;
      }

      sessionStorage.setItem('JWT_TOKEN', response.data.token);
      const decodedToken = jwt.decode(response.data.token);

      if (decodedToken && typeof decodedToken === 'object' && decodedToken.exp) {
        sessionStorage.setItem('JWT_EXPIRE', (decodedToken.exp * 1000).toString());
      }

      userDispatch({
        type: updateEmail,
        payload: {
          email
        }
      });
      userDispatch({
        type: setFirstLogin,
        payload: {
          isFirstLogin: response.data.firstLogin
        }
      });
      userDispatch({
        type: updateName,
        payload: {
          firstName: response.data.firstName ? response.data.firstName : '',
          lastName: response.data.lastName ? response.data.lastName : ''
        }
      });
      userDispatch({
        type: setIsAdmin,
        payload: {
          isAdmin: !!(response.data.isAdmin === 'true' || response.data.isAdmin === true)
        }
      });
      userDispatch({
        type: setAccessRules,
        payload: {
          accessRules: response.data.accessRules ? response.data.accessRules : { static: [], dynamic: [] }
        }
      });

      setIsAuthenticated(true);
      if (
        (response.data.isFactoryUser === 'true' || response.data.isFactoryUser === true) &&
        (response.data.isAdmin === 'false' || response.data.isAdmin === false)
      ) {
        history.push('/factory/registration');
      } else if (response.data.firstLogin) {
        history.push('/changePassword');
      } else {
        history.push('/');
      }
    } catch (error) {
      console.error(error);
      setErroredResponse(true);
      setIsAuthenticated(false);
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      // if (userData.isFirstLogin) {
      //   history.push("/changePassword");
      // } else {
      history.push('/');
      // }
    }
  }, [history, isAuthenticated]);

  return (
    <div className={classes.loginContainer}>
      <div className={classes.loginForm}>
        <FormControl
          component="form"
          id="login"
          size="medium"
          onSubmit={(e: any) => {
            handleSubmit(e);
          }}
        >
          <img
            className={classes.loginLogo}
            alt=""
            src="https://files.onlosant.com/5de90f3b0ac5cc0007fc030d/images/Qstraint-Master-Logo.jpg"
          />
          <FormControl className={classes.loginInputFormControl}>
            <InputLabel className={classes.loginLabel} htmlFor="username">
              Username
            </InputLabel>
            <Input
              id="username"
              value={email}
              className={classes.loginInput}
              required
              disableUnderline
              onInput={(e: any) => {
                setErroredResponse(false);
                setEmail(e.target.value);
              }}
            />
          </FormControl>
          <FormControl className={classes.loginInputFormControl}>
            <InputLabel className={classes.loginLabel} htmlFor="password">
              Password
            </InputLabel>
            <Input
              id="password"
              value={password}
              className={classes.loginInput}
              disableUnderline
              type="password"
              required
              error={erroredResponse}
              onInput={(e: any) => {
                setErroredResponse(false);
                setPassword(e.target.value);
              }}
            />
            {erroredResponse && (
              <span className={classes.errorText}>Invalid username / password</span>
            )}
          </FormControl>
          <Button fullWidth={false} className={classes.loginButton} type="submit">
            Login
          </Button>
          <Button fullWidth={false} className={classes.loginButton} onClick={() => handleResetPasswordClick()}>
            Forgot Password
          </Button>
        </FormControl>
      </div>
      {/* </Grid> */}
    </div>
  );
};

export { Login };
