import React, { useState, ReactElement, useMemo, useRef, useEffect, useCallback } from 'react';
import { useStyles } from '../sectionStyle';
import { AlertsTotalsRow } from './AlertsTotalsRow';
import { FilterRow } from '../FilterRow';
import { getAlerts } from '../../endpoints/alerts';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import usePaginationProps from '../Table/usePaginationProps';
import usePaginatedData from '../Table/usePaginatedData';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { unitsSummary } from '../../endpoints/units';
import AlertsTable from './AlertsTable';
import useUnitLinkGenerator from '../useUnitLinkGenerator';
import { IAlert } from '../../interfaces/alerts';

type TStatus = 'new' | 'acknowledged';

const Alerts = () : ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const unitLinkGenerator = useUnitLinkGenerator();

  const initialLevelFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.level && queryParams.level === 'service-required') {
        return 'errorAndCritical';
      }
      if (queryParams.level && !Array.isArray(queryParams.level)) {
        return queryParams.level;
      }
    }
    return '';
  };

  const [levelFilter, setLevelFilter] = useState(initialLevelFilter);
  const [unitFilter, setUnitFilter] = useState('');
  const [stateFilter, setStatusFilter] = useState<TStatus>('new');

  // Track if not mounted so state isnt updated on non-existent component
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const filters = useMemo( () => {
    const filtersObj : {[key:string]:string} = {};
    if (stateFilter) {
      filtersObj.state = stateFilter;
    }
    if (levelFilter) {
      filtersObj.level = levelFilter;
    }
    if (unitFilter) {
      filtersObj.unitIds = unitFilter;
    }
    return filtersObj;
  }, [levelFilter, unitFilter, stateFilter]);

  const paginationProps = usePaginationProps(getAlerts, { sortColumn: 'creationDate', sortDirection: 'desc', filters });
  const alertsRequest = usePaginatedData(paginationProps);

  const handleStatusFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setStatusFilter(event.target.value);
  };

  const handleLevelFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setLevelFilter(event.target.value);
  };

  const handleUnitFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setUnitFilter(event.target.value);
  };

  const onClickHandler = useCallback((alert: IAlert) => {
    const link = unitLinkGenerator(alert.unitId);
    if (link) {
      history.push(link);
    }
    return null;
  }, [history, unitLinkGenerator]);

  const showLoadingBackdrop = Boolean(
    alertsRequest.status === 'loading'
    || alertsRequest.status === 'error');

  return (
    <Grid container spacing={0} className={classes.root}>

      <Backdrop data-testid="debug-backdrop" open={showLoadingBackdrop} className={classes.backdrop}>
        { alertsRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        { alertsRequest.status === 'error' && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Alerts" />
      <div className={classes.filterRow}>
        <FilterRow onChange={handleStatusFilterChange} label="state" selected={stateFilter}>
          <option value="">State</option>
          <option value="new">New</option>
          <option value="acknowledged">Acknowledged</option>
        </FilterRow>
        <FilterRow onChange={handleLevelFilterChange} label="alert level" selected={levelFilter}>
          <option value="">Level</option>
          <option value="info">Info</option>
          <option value="warning">Warning</option>
          <option value="errorAndCritical">Service Required</option>
        </FilterRow>
        <FilterRow onChange={handleUnitFilterChange} label="unit" selected={unitFilter}>
          <FilterOptionsFromApi name="Unit" optionsPath={unitsSummary} />
        </FilterRow>
      </div>
      <div className={classes.alertRow}>
        <AlertsTotalsRow
          totalCount={alertsRequest.totalCount || alertsRequest.totalCount === 0
            ? alertsRequest.totalCount
            : null }
          totalWarning={
            alertsRequest.summary
            && (alertsRequest.summary.warning || alertsRequest.summary.warning === 0 )
              ? alertsRequest.summary.warning
              : null }
          totalCritical={
            alertsRequest.summary
            && (alertsRequest.summary.critical || alertsRequest.summary.critical === 0 )
            && (alertsRequest.summary.error || alertsRequest.summary.error === 0 )
              ? parseInt(alertsRequest.summary.critical, 10) + parseInt(alertsRequest.summary.error, 10)
              : null }
        />
      </div>
      <AlertsTable
        totalCount={alertsRequest.totalCount}
        refetch={alertsRequest.refetch}
        paginationProps={paginationProps}
        items={alertsRequest.items}
        handleRowClick={onClickHandler}
      />
    </Grid>
  );
};

Alerts.propTypes = {};

export { Alerts };
