import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

type propTypes = {
  direction: string;
  marginRight?: number;
};

export const ArrowIcon = ({ direction, marginRight }: propTypes) : ReactElement => {
  const sharedStyles = {
    height: '28px',
    width: '28px',
    borderRadius: '4px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    marginRight
  };

  const classes = makeStyles(() => ({
    up: {
      ...sharedStyles,
      background: '#1B73FF'
    },
    down: {
      ...sharedStyles,
      background: '#F55252'
    }
  }))();

  return direction === 'up' ? (
    <div className={classes.up}>
      <ArrowUpwardIcon />
    </div>
  ) : (
    <div className={classes.down}>
      <ArrowDownwardIcon />
    </div>
  );
};
