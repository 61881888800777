import React, { useState } from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import usePaginationProps from '../../Table/usePaginationProps';
import usePaginatedData from '../../Table/usePaginatedData';
import { Backdrop, CircularProgress, Grid } from '@material-ui/core';
import PageDataConfig from '../../PageDataConfig';
import { useStyles } from '../../sectionStyle';
import { unitsSummary } from '../../../endpoints/units';
import { LocationMap } from '../LocationMap';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { TUnitSummary } from '../../../interfaces/units';
import MarkerNormal from '../../../assets/marker--normal.svg';
import useUnitLinkGenerator from '../../useUnitLinkGenerator';

const UnitsMap = () : React.ReactElement => {
  const classes = useStyles();
  const paginationProps = usePaginationProps(unitsSummary);
  const unitSummaryRequest = usePaginatedData(paginationProps);
  const [markerOpened, setMarkerOpened] = useState('');
  const unitLinkGenerator = useUnitLinkGenerator();
  const openInfoWindow = (unitId: string) : void => {
    setMarkerOpened(unitId);
  };

  const closeInfoWindow = () : void => {
    setMarkerOpened('');
  };

  return (
    <Grid container spacing={0} className={cn(classes.root, classes.relative)}>
      <Backdrop data-testid="unitmap-backdrop" open={Boolean(unitSummaryRequest.status === 'loading' || unitSummaryRequest.status === 'error')} className={cn(classes.backdrop, classes.relativeBackdrop)}>
        {unitSummaryRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        {unitSummaryRequest.status === 'error' && (<span className={classes.loadingError}>Could not load map data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Map" />
      <LocationMap >
        { unitSummaryRequest.items.filter( (item: TUnitSummary) => item.latitude && item.longitude ).map( ( item: TUnitSummary ) => {
          const link = unitLinkGenerator(item.id);
          return (<Marker
            position={{
              lat: item.latitude,
              lng: item.longitude
            }}
            icon={{
              url: MarkerNormal
            }}
            onClick={() => openInfoWindow(item.id)}
          >
            { markerOpened === item.id && (
              <InfoWindow onCloseClick={closeInfoWindow}>
                <div>
                  { link ? <Link className={classes.infoWindowLink} to={link}>{item.name}</Link> : <span className={classes.infoWindowLink}>{item.name}</span> }
                </div>
              </InfoWindow>
            )}
          </Marker>);
        })
        }
      </LocationMap>
    </Grid>
  );
};

export default UnitsMap;
