import { useState, useEffect } from 'react';
import { IUsePaginationProps } from '../../interfaces/tables/';

const usePaginationProps = (initialEndpoint : string, defaults : { [key:string] : any } = {} ) : IUsePaginationProps => {
  const [page, setPage] = useState<number>(defaults.page ? defaults.page : 0);
  const [rowsPerPage, setRowsPerPage ] = useState<number>(defaults.rowsPerPage ? defaults.rowsPerPage : 10);
  const [sortDirection, setSortDirection ] = useState<'asc' | 'desc'>(defaults.sortDirection? defaults.sortDirection : 'asc');
  const [sortColumn, setSortColumn ] = useState<string>(defaults.sortColumn ? defaults.sortColumn : '');
  const [filters, setFilters] = useState<{[key:string]:string}>(defaults.filters ? { ...defaults.filters } : {});
  const [endpoint, setEndpoint] = useState<string>(initialEndpoint);

  useEffect( () => {
    if (defaults.page && defaults.page !== page) {
      setPage(defaults.page);
    }
    if (defaults.rowsPerPage && defaults.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(defaults.rowsPerPage);
      setPage(0);
    }
    if (defaults.sortDirection&& defaults.sortDirection !== sortDirection) {
      setSortDirection(defaults.sortDirection);
      setPage(0);
    }
    if (defaults.sortColumn && defaults.sortColumn !== sortColumn) {
      setSortColumn(defaults.sortColumn);
      setPage(0);
    }
    if (defaults.filters && JSON.stringify(defaults.filters) !== JSON.stringify(filters)) {
      setFilters({ ...defaults.filters });
      setPage(0);
    }
    setEndpoint(initialEndpoint);

  }, [defaults.page, defaults.rowsPerPage, defaults.sortDirection, defaults.sortColumn, defaults.filters, page, rowsPerPage, sortDirection, sortColumn, filters, initialEndpoint]);

  return {
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    sortDirection,
    setSortDirection,
    sortColumn,
    setSortColumn,
    filters,
    setFilters,
    endpoint,
    setEndpoint
  };
};

export default usePaginationProps;
