import { useContext } from 'react';
import { useHistory } from 'react-router';
import { AuthContext } from '../state-manipulation/providers/AuthWrapper';
import useUserData from '../state-manipulation/hooks/useUserData';
import { logout } from '../state-manipulation/reducers/user/types';

type nullFunc = () => void;

const useLogout = () : nullFunc => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const userData = useUserData();
  const userDispatch = userData ? userData[1] : () => true;

  const logUserOut = () => {
    userDispatch({
      type: logout,
      payload: {}
    });
    if (authContext?.setIsAuthenticated) {
      authContext.setIsAuthenticated(false);
    }
    history.push('/login');
  };

  return logUserOut;
};

export default useLogout;
