import React, { ReactElement } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import { Table, TableBody } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { IEnhancedTableProps } from '../../interfaces/tables';
import EnhancedTableHead from './EnhancedTableHead';
import { useStyles } from './styles';

const EnhancedTable = ({
  columns,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  hidePagination,
  hideControlColumn,
  children,
  sortDirection,
  sortColumn
}: IEnhancedTableProps) : ReactElement => {
  const classes = useStyles();

  const handleChangePage = (_event: any, newPage: number ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string } }) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
          <EnhancedTableHead
            columns={columns}
            order={sortDirection}
            numSelected={0}
            orderBy={sortColumn}
            rowCount={totalCount}
            hideControlColumn={hideControlColumn}
          />
          <TableBody className={classes.tbody}>
            {children}
          </TableBody>
        </Table>
      </TableContainer>
      {!hidePagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={classes.pagination}
        />
      )}
    </>
  );
};

export default EnhancedTable;
