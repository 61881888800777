export const updateEmail = 'UPDATE_EMAIL';
export const setFirstLogin = 'SET_FIRST_LOGIN';
export const updateName = 'UPDATE_NAME';
export const setIsAdmin = 'SET_IS_ADMIN';
export const setAccessRules = 'SET_ACCESS_RULES';
export const logout = 'LOGOUT';

export enum EPermission {
  'regions',
  'regions:add',
  'regions:edit',
  'regions:delete',
  'properties',
  'properties:add',
  'properties:edit',
  'properties:delete',
  'vehicles',
  'vehicles:add',
  'vehicles:edit',
  'units',
  'units:add',
  'units:edit',
  'units:delete',
  'units:debug',
  'users',
  'users:add',
  'users:edit',
  'users:delete',
  'overview',
  'maps',
  'alerts',
  'alerts:acknowledge',
  'alwaystrue',
  'alwaysfalse'
}

export type TPermissions = keyof typeof EPermission;


export type TAccessRules = {
  static: Array<keyof typeof EPermission>,
  dynamic: { [key in TPermissions]? : (val:string | undefined) => boolean };
};

export type TUserData = {
  email?: string | null;
  isFirstLogin?: boolean | string | null;
  isAdmin?: boolean | string | null;
  firstName?: string | null;
  lastName?: string | null;
  accessRules?: TAccessRules;
};

