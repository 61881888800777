import React, { ReactElement, useEffect } from 'react';
import usePageData from '../state-manipulation/hooks/usePageData';
import { setPageName } from '../state-manipulation/reducers/page-data/types';

const PageDataConfig = ({ title } : {title: string}) : ReactElement => {
  const pageDataReducer = usePageData();
  const pageDispatch = pageDataReducer ? pageDataReducer[1] : () => true;

  useEffect(() => {
    pageDispatch({ type: setPageName, payload: { pageName: title } });
  }, [pageDispatch, title]);

  return <></>;
};

export default PageDataConfig;
