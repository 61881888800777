/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { ReactElement, useState } from 'react';
import {
  LineChart,
  CartesianGrid,
  Tooltip,
  Line,
  ResponsiveContainer,
  YAxis,
  AreaChart,
  Area,
  BarChart,
  Bar,
  Brush,
  XAxis,
  ReferenceLine
} from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { Card, Checkbox, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { AspectRatio, Close, Visibility } from '@material-ui/icons';
import moment from 'moment';
import { IDataKey } from '../../../interfaces/data';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 200,
    flexBasis: 'calc(50% - 5px)',
    marginBottom: '10px'
  },
  square: {
    width: 15,
    height: 15,
    padding: 0,
    margin: 0,
    marginRight: 5,
    display: 'inline-block',
    position: 'relative',
    top: 2
  },
  fullScreen: {
    position: 'fixed',
    left: 25,
    right: 25,
    top: 25,
    height: '65vh',
    paddingBottom: '20px',
    zIndex: 1200,
    outline: '9999px solid rgba(0,0,0,.4)'
  },
  chart: {
    height: 200
  },
  header: {
    fontFamily: 'lato',
    fontWeight: 'bold',
    padding: '7px',
    fontSize: '10pt',
    background: '#f2f2f2',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subtleText: {
    fontFamily: 'lato',
    fontWeight: 'normal',
    padding: '7px',
    color: '#777'
  },
  referenceLineLabel: {
    fontFamily: 'sans-serif',
    fontSize: '8pt'
  },
  iconButton: {
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    color: '#aaa'
  },
  iconButtonActive: {
    border: 0,
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    color: '#013CA6'
  },
  optionsPane: {
    padding: '1rem'
  },
  optionsHeading: {
    fontFamily: 'lato',
    fontWeight: 'normal',
    padding: '7px',
    color: '#777'
  },
  optionsGroup: {
    'borderTop': '1px solid #eee',
    '& .MuiCheckbox-root': {
      padding: 0
    },
    '& .MuiFormControlLabel-root': {
      marginLeft: '10px'
    },
    'fontSize': '6pt'
  }
}));

type data = Array<any>;
type propTypes = {
  data: unknown;
  title: string;
  unit: string;
  chartType: string;
  lowAlert?: number;
  highAlert?: number;
  icon?: boolean;
  dataKeys?: Array<IDataKey>;
  toggleAttribute?: (attr:string) => void;
};

const dateFormatter = (timeInMS: number | string) => moment(timeInMS).format('M/D HH:mm');
const singleValueFormatter = (val: string | number) => {
  if (typeof val === 'number' && isNaN(val)) {
    return 'N/A';
  }
  if (typeof val === 'string') {
    return Number(Number(val).toFixed(2));
  }
  return Number(val.toFixed(2));
};
const valueFormatter = (val: string | number | (string | number)[]) => {
  if (Array.isArray(val)) {
    return val.map(singleValueFormatter);
  }
  return singleValueFormatter(val);
};

const tickStyles = {
  fill: '#999'
};

const areaChart = ({
  data,
  rounded = true,
  includeControls = false,
  dataKeys,
  useZeroedDomain
}: {
  data: data;
  rounded?: boolean;
  includeControls?: boolean;
  dataKeys: Array<IDataKey>;
  useZeroedDomain: boolean;
}) => (
  <AreaChart data={data} height={30} margin={{ top: 30, bottom: 20 }}>
    {rounded ? <CartesianGrid horizontal={false} /> : <CartesianGrid />}
    <YAxis tick={tickStyles} domain={[useZeroedDomain ? 0 : 'minValue', 'auto']} />
    <XAxis dataKey="time" tick={tickStyles} tickFormatter={dateFormatter} />
    <Tooltip formatter={valueFormatter} labelFormatter={dateFormatter} />
    {dataKeys.map((key) => (
      <Area
        key={key.attribute}
        isAnimationActive={false}
        type="monotone"
        dataKey={key.attribute}
        name={key.name} />
    ))}
    {includeControls && <Brush dataKey="time" height={30} stroke="#013CA6" tickFormatter={dateFormatter} />}
  </AreaChart>
);

const renderCustomLabel = (context:any, text: string, offset: number, classes: any) => {
  console.error(context);
  return (
    <g>
      {/* <rect fill="#fff" x={Number(context.viewBox.x) + Number((context.viewBox.width/2) - 12)} y={Number(context.viewBox.y) + offset - 8} width={24} height={16} /> */}
      <text fill="#f00" x={context.viewBox.x} y={Number(context.viewBox.y) + offset} className={classes.referenceLineLabel} text-anchor="middle"><tspan x="100.5" dy="0.355em">{text}</tspan></text>
    </g>
  );
};

const lineChart = ({
  data,
  includeControls = false,
  dataKeys,
  lowAlert,
  highAlert,
  useZeroedDomain,
  classes
}: {
  data: data;
  includeControls: boolean;
  lowAlert?: number;
  highAlert?: number;
  dataKeys: Array<IDataKey>;
  useZeroedDomain: boolean;
  classes?: any;
}) => {

  return (
    <LineChart data={data} margin={{ top: 15, right: 20, left: -15, bottom: 5 }}>
      <CartesianGrid vertical={false} />
      <YAxis tick={tickStyles} domain={[useZeroedDomain ? 0 : 'minValue', 'auto']} />
      <XAxis minTickGap={50} dataKey="time" tick={tickStyles} tickFormatter={dateFormatter} />
      <Tooltip formatter={valueFormatter} labelFormatter={dateFormatter} />
      {(lowAlert || lowAlert === 0) && (
        <ReferenceLine isFront alwaysShow y={lowAlert} label={(context) => renderCustomLabel(context, `LOW (${lowAlert})`, lowAlert < 10 ? -8 : 8, classes) } stroke="red" strokeDasharray="3 3" />
      )}
      {(highAlert || highAlert === 0) && (
        <ReferenceLine isFront alwaysShow y={highAlert} label={(context) => renderCustomLabel(context, `HIGH (${highAlert})`, -8, classes) } stroke="red" strokeDasharray="3 3" />
      )}
      {dataKeys.filter((key) => key.show).map((key) => (
        <Line
          isAnimationActive={false}
          type="monotone"
          dataKey={key.attribute}
          key={key.attribute}
          name={key.name}
          stroke={key.color || '#013CA6'}
          strokeWidth={2}
          dot={{ fill: key.color || '#013CA6', r: 2 }}
        />
      ))}


      { includeControls && (
        <Brush dataKey="time" height={30} stroke="#013CA6" tickFormatter={dateFormatter} />
      )}
    </LineChart>
  );
};

const barChart = ({
  data,
  includeControls = false,
  dataKeys,
  useZeroedDomain
}: {
  data: data;
  includeControls?: boolean;
  dataKeys: Array<IDataKey>;
  useZeroedDomain: boolean;
}) => (
  <BarChart data={data} margin={{ top: 15, right: 20, left: -15, bottom: 5 }}>
    <CartesianGrid vertical={false} />
    <XAxis dataKey="time" tick={tickStyles} tickFormatter={dateFormatter} />
    <YAxis tick={tickStyles} domain={[useZeroedDomain ? 0 : 'minValue', 'auto']} />
    <Tooltip formatter={valueFormatter} labelFormatter={dateFormatter} />
    {dataKeys.map((key, i) => (
      <Bar
        key={key.attribute}
        isAnimationActive={false}
        dataKey={key.attribute}
        name={key.name}
        fill={i === 1 ? '#bcdffc' : '#013CA6'}
        barSize={30} />
    ))}
    { includeControls && (
      <Brush dataKey="time" height={30} stroke="#013CA6" tickFormatter={dateFormatter} />
    )}
  </BarChart>
);

export const DataChart = (props: propTypes) : ReactElement => {
  const {
    data: dataRaw, dataKeys, title, unit, chartType, lowAlert, highAlert, toggleAttribute
  } = props;
  const data  = dataRaw as data;
  const classes = useStyles();
  const [showOptions, setShowOptions] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [useZeroedDomain, setUseZeroedDomain] = useState(true);

  /* This switches based off chartType to give us the desired chart component with it's requried props */
  const chart = ((includeControls:boolean) => {
    switch (chartType) {
      case 'area':
        return dataKeys && areaChart({ data, includeControls, dataKeys, useZeroedDomain });
      case 'line':
        return dataKeys && lineChart({
          data, lowAlert, highAlert, includeControls, dataKeys, useZeroedDomain, classes
        });
      default:
        return dataKeys && barChart({ data, includeControls, dataKeys, useZeroedDomain });
    }
  });

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen);
    setUseZeroedDomain(true);
  };

  const toggleShowOptions = () => {
    setShowOptions(!showOptions);
  };

  const header = (headerIcon : any, includeControls = false) => (
    <div className={classes.header}>
      <div>
        {title}
        <span className={classes.subtleText}>{unit}</span>
      </div>
      { includeControls && (
        <FormControlLabel
          control={(
            <Switch
              checked={useZeroedDomain}
              onChange={() => { setUseZeroedDomain(!useZeroedDomain); }}
              name="checkedB"
              color="primary"
            />
          )}
          label="Always include 0"
        />
      ) }
      <div>
        <button type="button" aria-label="options" style={{ marginRight: 5 }} className={showOptions ? classes.iconButtonActive : classes.iconButton } onClick={toggleShowOptions}><Visibility /></button>
        <button type="button" aria-label="fullscreen" className={classes.iconButton} onClick={toggleFullScreen}>{headerIcon}</button>
      </div>
    </div>
  );

  const chartContainer = (includeControls:boolean) => (
    <ResponsiveContainer width="95%" className={classes.chart}>
      {chart(includeControls)}
    </ResponsiveContainer>
  );

  return (
    <>
      <Card square className={classes.root}>
        { header(<AspectRatio />) }
        { !showOptions && chartContainer(false) }
        { toggleAttribute && showOptions && (
          <div className={classes.optionsPane}>
            <div className={classes.optionsHeading}>Show:</div>
            {dataKeys?.map( (key) => (
              <FormGroup key={key.attribute} className={classes.optionsGroup}>
                <FormControlLabel control={<Checkbox size="small" checked={key.show} onChange={ () => toggleAttribute(key.attribute)} />} label={<><div className={classes.square} style={{ backgroundColor: key.color || '#013CA6' }} />{key.name}</>} />
              </FormGroup>
            ))}
          </div>
        )}
      </Card>
      { fullScreen && (
        <Card square className={cx(classes.root, classes.fullScreen)}>
          { header(<Close />, true) }
          { !showOptions && chartContainer(true) }
          { toggleAttribute && showOptions && (
            <div className={classes.optionsPane}>
              <div className={classes.optionsHeading}>Show:</div>
              {dataKeys?.map( (key) => (
                <FormGroup key={key.attribute} className={classes.optionsGroup}>
                  <FormControlLabel control={<Checkbox size="small" checked={key.show} onChange={ () => toggleAttribute(key.attribute)} />} label={<><div className={classes.square} style={{ backgroundColor: key.color || '#013CA6' }} />{key.name}</>} />
                </FormGroup>
              ))}
            </div>
          )}
        </Card>
      )}
    </>
  );
};
