import React, { useEffect, useRef, useState } from 'react';
import ApiWrapper from '../../ApiWrapper';
import { IAlert } from '../../interfaces/alerts';
import { acknowledgeAlert as acknowledgeAlertEndpoint } from '../../endpoints/alerts';
import Confirm from '../Confirm';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from '../sectionStyle';

type TChildProps = {
  acknowledge: () => void
}

type TChild = (props : TChildProps) => React.ReactChild | React.ReactChild[];

type TProps = {
  alert: IAlert;
  children: TChild;
  success?: () => void;
}
const AcknowledgeAlert = ({ alert, children, success } : TProps) : React.ReactElement => {

  const classes = useStyles();

  // When user clicks acknowledge, IAlert object put in staging space for user to confirm
  const [acknowledgeAlertStaging, setAcknowledgeAlertStaging ] = useState<null | IAlert>(null);
  const [ackAlertNetworkRequest, setAckAlertNetworkRequest] = useState({ status: 'init' });

  // Track if not mounted so state isnt updated on non-existent component
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const stageAcknowledgeAlert = () => {
    setAcknowledgeAlertStaging(alert);
  };

  const acknowledgeAlert = async () => {
    if (!acknowledgeAlertStaging) {
      return;
    }
    try {
      setAckAlertNetworkRequest({ status: 'loading' });
      await ApiWrapper({
        url: acknowledgeAlertEndpoint(acknowledgeAlertStaging.id),
        method: 'POST'
      });

      if (isMounted.current) {
        setAckAlertNetworkRequest({ status: 'success' });
        setAcknowledgeAlertStaging(null);
        if (success) {
          success();
        }
      }
    } catch (e) {
      if (isMounted.current) {
        setAckAlertNetworkRequest({ status: 'error' });
        setAcknowledgeAlertStaging(null);
      }
      console.error('Error acknowledging alert:', e);
    }
  };

  const showLoadingBackdrop = ackAlertNetworkRequest.status === 'loading' || ackAlertNetworkRequest.status === 'error';

  return (
    <>
      <Backdrop data-testid="acknowledge-alert-debug-backdrop" open={showLoadingBackdrop} className={classes.backdrop}>
        { ackAlertNetworkRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        { ackAlertNetworkRequest.status === 'error' && (<span className={classes.loadingError}>Could not load acknowledge alert. Please refresh and try again.</span>)}
      </Backdrop>
      {acknowledgeAlertStaging && (
        <Confirm
          no={ () => setAcknowledgeAlertStaging(null) }
          yes={ acknowledgeAlert }
          yesText="Confirm"
        >
          <h1>{acknowledgeAlertStaging.deviceName}</h1>
          <h2>"{acknowledgeAlertStaging.subject}"</h2>
          <div>Are you sure you want to acknowledge this alert?</div>
        </Confirm>
      )}
      { children && children({ acknowledge: stageAcknowledgeAlert }) }
    </>);
};

export default AcknowledgeAlert;


//         { ackAlertNetworkRequest.status === 'error' && (<span className={classes.loadingError}>Could not acknowledge alert. Please refresh and try again.</span>)}
