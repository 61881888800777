/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, ReactElement } from 'react';
import { Button, FormControl, Grid, TextField, Backdrop, CircularProgress  } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { useStyles } from '../sectionStyle';
import { useForm } from 'react-hook-form';
import { IRequestStatus } from '../../interfaces/util';
import ApiWrapper from '../../ApiWrapper';
import { useHistory } from 'react-router-dom';
import { TVehicleFormData, TVehiclePostRequest } from '../../interfaces/vehicles';
import Can from '../../Can';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { propertiesSummary } from '../../endpoints/properties/properties';

const AddVehicle = () : React.ReactElement => {
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [modalDismissed, setModalDismissed] = useState(true);
  const history = useHistory();

  const dismissModal = () => {
    setModalDismissed(true);
  };

  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );
  const { register, handleSubmit, errors } = useForm<TVehicleFormData>();

  const onSubmit = async (data: TVehicleFormData): Promise<void> => {
    setModalDismissed(false);
    const formattedData : TVehiclePostRequest = {
      ...data,
      active: data.active === 'true'
    };

    try {
      setRequestStatus({ loading: true, success: false, error: false });
      await ApiWrapper({
        url: '/api/vehicles',
        method: 'POST',
        data: formattedData
      });
      setRequestStatus({ loading: false, success: true, error: false });

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => () => {
        history.push('/vehicles');
        return null;
      } );
    } catch (error) {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error(error);
    }
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="debug-backdrop" onClick={modalAction} open={!modalDismissed} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {requestStatus.error && (<span className={classes.loadingError}>Could not add vehicle. Please refresh and try again.</span>)}
        {requestStatus.success && (<span className={classes.loadingError}>Vehicle added successfully.</span>)}
      </Backdrop>
      <PageDataConfig title="Add Vehicle" />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
        <Can perform="properties" yes={() => (
          <FormControl className={classes.formControl}>
            <TextField
              id="property"
              label="Property"
              name="property"
              select
              SelectProps={{
                native: true
              }}
              inputRef={register({ required: true })}
              error={Boolean(errors.property)}
              helperText={errors.property ? 'Property must be selected' : ''}
              className={classes.formInput}
            >
              <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} />
            </TextField>
          </FormControl>
        )} />
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="Name"
            name="name"
            inputRef={register({ required: true, minLength: 3 })}
            error={Boolean(errors.name)}
            helperText={errors.name ? 'Vehicle name is required' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="routes"
            label="Routes"
            name="routes"
            inputRef={register({ })}
            error={Boolean(errors.routes)}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="active"
            label="Active"
            name="active"
            select
            SelectProps={{
              native: true
            }}
            inputRef={register({ required: true })}
            error={Boolean(errors.active)}
            helperText={errors.active ? 'Active is required' : ''}
            className={classes.formInput}
          >
            <option value=""></option>
            <option value="true">True</option>
            <option value="false">False</option>
          </TextField>
        </FormControl>
        <Button type="submit" className={classes.borderedButton}>Add</Button>
      </form>
    </Grid>
  );
};

export default AddVehicle;
