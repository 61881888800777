import React, { ReactElement } from 'react';
import { useHistory, useLocation, BrowserRouter as Router, Link } from 'react-router-dom';
import { Container, Drawer, List, Typography, ListItem } from '@material-ui/core';
import { useStyles } from '../material-theme-styles/default';
import Can from '../Can';
import { TPermissions } from '../state-manipulation/reducers/user/types';

type TRouteMenuItem = {
  name: string;
  permission: TPermissions;
  route: string;
  altRoutes?: string[];
}

const drawerListItems : TRouteMenuItem[] = [
  {
    name: 'Overview',
    permission: 'overview',
    route: '/'
  },
  {
    name: 'Alerts',
    permission: 'alerts',
    route: '/alerts'
  },
  {
    name: 'Regions',
    permission: 'regions',
    route: '/regions'
  },
  {
    name: 'Properties',
    permission: 'properties',
    route: '/properties'
  },
  {
    name: 'Vehicles',
    permission: 'vehicles',
    route: '/vehicles'
  },
  {
    name: 'Units',
    permission: 'units',
    route: '/units',
    altRoutes: ['/unit']
  },
  {
    name: 'Users',
    permission: 'users',
    route: '/users',
    altRoutes: ['/user']
  }
];

export const Navbar = () : ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const isSelected = (item: TRouteMenuItem) => {
    if (item.route === '/') {
      return location.pathname === '/';
    }
    if (location.pathname.indexOf(item.route) !== -1) {
      return true;
    }
    if (item.altRoutes) {
      return item.altRoutes.some( (altRoute) => location.pathname.indexOf(altRoute) !== -1 );
    }
    return false;
  };

  return (
    <Router>
      <Drawer variant={'permanent'} anchor={'left'} className={classes.drawer}>
        <Container disableGutters className={classes.drawerPaper}>
          <Container disableGutters className={classes.drawerLogoContainer}>
            <img
              className={classes.drawerLogo}
              alt=""
              src="https://files.onlosant.com/5de90f3b0ac5cc0007fc030d/images/Qstraint-Master-Logo.png"
            />
          </Container>
          {drawerListItems.map((item) => (
            <Can
              key={item.name}
              perform={item.permission}
              yes={ () => (
                <List>
                  <ListItem
                    button
                    onClick={() => {
                      history.push(item.route);
                    }}
                    key={item.name}
                    className={ isSelected(item) ? classes.drawerListItemSelected : classes.drawerListItem}
                    alignItems="center"
                    component={Link}
                    to={item.route}
                  >
                    <Typography>
                      {item.name}
                    </Typography>
                  </ListItem>
                </List>
              ) }
            />
          ))}
        </Container>
      </Drawer>
    </Router>
  );
};
