import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap } from 'react-google-maps';

type propTypes = { children?: Array<ReactElement> | ReactElement };

export const InnerMap = ({ children }: propTypes) : ReactElement => {
  const mapRef = useRef<GoogleMap>(null);

  const focusOnMarkers = useCallback(() => {
    if (!window.google) {
      return;
    }
    const bounds = new window.google.maps.LatLngBounds();
    if (mapRef && mapRef.current) {
      let found = false;
      if (children) {
        React.Children.forEach(children, (child) => {
          if (child && child.props && child.props.position) {
            found = true;
            bounds.extend(
              new window.google.maps.LatLng(
                child.props.position.lat,
                child.props.position.lng
              )
            );
          }
        });
      }
      if (!found) {
        bounds.extend({ lat: 47, lng: -68 }); // maine
        bounds.extend({ lat: 32, lng: -116 }); // san diego
      } else {
        const offset = 0.001;
        const center = bounds.getCenter();
        bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
        bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));
      }
      mapRef.current.fitBounds(bounds);
    }
  }, [children]);

  useEffect(() => {
    focusOnMarkers();
  }, [focusOnMarkers]);

  return (
    <GoogleMap
      defaultCenter={{ lat: 39.809734, lng: -98.55562 }}
      defaultZoom={15}
      ref={mapRef}
    >
      {children}
    </GoogleMap>
  );
};

export default withScriptjs(
  withGoogleMap(InnerMap)
);
