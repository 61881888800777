import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: '99%'
  },
  table: {
    minWidth: 650,
    width: '99%',
    background: 'white',
    overflowY: 'scroll'
  },
  tbody: {
    '& td': {
      padding: '15px 10px',
      border: '1px solid #e7e7e7'
    },
    '& tr': {
      '&:nth-child(odd)': {
        backgroundColor: '#f8f8f8'
      }
    },
    '& tr:hover': {
      cursor: 'pointer',
      backgroundColor: '#a7a7a7'
    }
  },
  container: {
    height: '100%',
    position: 'relative'
  },
  pagination: {
    position: 'sticky',
    location: 'bottom',
    background: 'white',
    alignSelf: 'center',
    alignContent: 'center',
    width: '100%',
    marginRight: '10%',
    marginleft: '10%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));
