import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useStyles } from '../sectionStyle';
import EnhancedTable from '../Table/EnhancedTable';
import { Backdrop, Button, CircularProgress, Grid, TableCell, TableRow } from '@material-ui/core';
import { EditOutlined as EditIcon } from '@material-ui/icons';
import PageDataConfig from '../PageDataConfig';
import { ITableColumn } from '../../interfaces/tables';
import { getProperties } from '../../endpoints/properties/properties';
import { regionsSummary } from '../../endpoints/regions/regions';
import { IProperty } from '../../interfaces/properties';
import { useHistory, useLocation } from 'react-router-dom';
import usePaginatedData from '../Table/usePaginatedData';
import usePaginationProps from '../Table/usePaginationProps';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import Can from '../../Can';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { FilterRow } from '../FilterRow';

const Properties = () : ReactElement => {

  const location = useLocation();

  const initialRegionFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.region && !Array.isArray(queryParams.region)) {
        return queryParams.region;
      }
    }
    return '';
  };

  const classes = useStyles();
  const history = useHistory();
  const [regionFilter, setRegionFilter] = useState(initialRegionFilter);

  const filters = useMemo( () => {
    const filtersObj : {[key:string]:string} = {};

    if (regionFilter) {
      filtersObj.region = regionFilter;
    }
    return filtersObj;
  }, [regionFilter]);

  const paginationProps = usePaginationProps(getProperties, { filters });
  const propertiesRequest = usePaginatedData(paginationProps);

  const onClickHandler = useCallback((property: IProperty) => {
    history.push(`/vehicles/?property=${property.id}`);
    return null;
  }, [history]);

  const columns : Array<ITableColumn> = useMemo( () => ([
    {
      id: 'name', label: 'Name', numeric: false, sortable: true, disablePadding: true
    },
    {
      id: 'vehicles', label: '# Vehicles', numeric: true, sortable: false, disablePadding: true
    },
    {
      id: 'street', label: 'Street', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'city', label: 'City', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'state', label: 'State', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'zip', label: 'ZIP', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'contact', label: 'Contact', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'phone', label: 'Phone', numeric: true, sortable: false, disablePadding: true
    },
    {
      id: 'actions', label: 'Actions', numeric: false, sortable: false, disablePadding: true
    }
  ]), []);

  const propertyRows = useMemo( () => {
    return propertiesRequest.items.map( (property) => (
      <TableRow
        hover
        tabIndex={-1}
        key={property.id}
        onClick={ () => onClickHandler(property) }
      >
        <TableCell size="small" align="center" key={'name'}>
          {property.name}
        </TableCell>
        <TableCell size="small" align="center" key={'vehicles'}>
          {property.vehicles.length}
        </TableCell>
        <TableCell size="small" align="center" key={'street'}>
          {property.street}
        </TableCell>
        <TableCell size="small" align="center" key={'city'}>
          {property.city}
        </TableCell>
        <TableCell size="small" align="center" key={'state'}>
          {property.state}
        </TableCell>
        <TableCell size="small" align="center" key={'zip'}>
          {property.zip}
        </TableCell>
        <TableCell size="small" align="center" key={'contact'}>
          {property.contact}
        </TableCell>
        <TableCell size="small" align="center" key={'phone'}>
          <NumberFormat displayType={'text'} format="+# (###) ###-####" mask="_" value={property.phone} />
        </TableCell>
        <TableCell size="small" align="center" key={'actions'}>
          <Can perform="properties:edit"
            yes={() => (
              <EditIcon
                data-testid="edit-property"
                aria-label="Edit Property"
                className={classes.subtleActionItem}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(`/property/${String(property.id)}/edit`);
                }
                } />
            )} />
        </TableCell>
      </TableRow>
    ));
  }, [propertiesRequest.items, onClickHandler, classes.subtleActionItem, history]);


  const handleFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setRegionFilter(event.target.value);
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="debug-backdrop" open={Boolean(propertiesRequest.status === 'loading' || propertiesRequest.status === 'error')} className={classes.backdrop}>
        {propertiesRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        {propertiesRequest.status === 'error' && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Properties" />
      <Can
        perform="properties:add"
        yes={ () => (
          <div className={classes.sectionSubmenu}>
            <Button fullWidth={false} className={classes.borderedButton} type="button" onClick={() => {
              history.push('/properties/add');
            }}>
              Add Property
            </Button>
          </div>
        )} />
      <Can perform="regions" yes={() => (
        <div className={classes.filterRow}>
          <FilterRow onChange={handleFilterChange} selected={regionFilter}>
            <FilterOptionsFromApi name="Region" optionsPath={regionsSummary} />
          </FilterRow>
        </div>)} />
      <div className={classes.table}>
        <EnhancedTable
          columns={columns}
          totalCount={propertiesRequest.totalCount}
          {...paginationProps}
          hideControlColumn
        >
          {propertyRows}
        </EnhancedTable>
      </div>
    </Grid>
  );
};

Properties.propTypes = {};

export { Properties };
