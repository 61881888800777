import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Typography, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOverviewProps, IOverviewAlerts } from '../../interfaces/overview/index';
import { check } from 'prettier';
import Can, { checkAll } from '../../Can';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 250,
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  contentRow: {
    display: 'flex',
    flexFLow: 'row nowrap',
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'baseline',
    padding: '10px',
    maxHeight: '25px'
  },
  subtleLink: {
    textDecoration: 'none'
  },
  title: {
    fontSize: 14
  },
  contentValue: {
    fontWeight: 'bold',
    fontSize: 16
  },
  hr: {
    border: '2px solid red'
  }
}));

const TotalAlertsCard = ({ data }: {data: IOverviewAlerts}) : ReactElement => {
  const classes = useStyles();

  const { info, warning, error, critical } = data;
  const total = info + warning + error + critical;
  return (
    <Card square className={classes.root}>
      <Can
        perform="alerts"
        yes={({ children } : {children:any}) => <Link className={classes.subtleLink} to="/alerts">{children}</Link> }
        no={({ children } : {children:any}) => <>{children}</> }
        propsToPass={{
          children: (
            <div className={classes.contentRow}>
              <FontAwesomeIcon icon="bell" color="#099aff" size="1x" />
              <Typography className={classes.title} color="textPrimary" gutterBottom>
                Total Alerts
              </Typography>
              <Typography className={classes.contentValue} color="textSecondary" gutterBottom>
                {total >= 0 && total}
              </Typography>
            </div>)
        }}
      />
      <Divider variant="fullWidth" />
      <Can
        perform="alerts"
        yes={({ children } : {children:any}) => <Link className={classes.subtleLink} to="/alerts?level=warning">{children}</Link> }
        no={({ children } : {children:any}) => <>{children}</> }
        propsToPass={{
          children: (
            <div className={classes.contentRow}>
              <FontAwesomeIcon icon="exclamation-triangle" color="#ffc785" size="1x" />
              <Typography className={classes.title} color="textPrimary" gutterBottom>
                Warning
              </Typography>
              <Typography className={classes.contentValue} color="textSecondary" gutterBottom>
                {warning >= 0 && warning}
              </Typography>
            </div>)
        }}
      />
      <Divider variant="fullWidth" />
      <Can
        perform="alerts"
        yes={({ children } : {children:any}) => <Link className={classes.subtleLink} to="/alerts?level=service-required">{children}</Link> }
        no={({ children } : {children:any}) => <>{children}</> }
        propsToPass={{
          children: (
            <div className={classes.contentRow}>
              <FontAwesomeIcon icon="exclamation-circle" color="#f81514" size="1x" />
              <Typography className={classes.title} color="textPrimary">
                Service Required
              </Typography>
              <Typography className={classes.contentValue} color="textSecondary" gutterBottom>
                {error + critical >= 0 && error + critical}
              </Typography>
            </div>)
        }}
      />
    </Card>
  );
};

export { TotalAlertsCard };
