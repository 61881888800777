import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

type propTypes = {
  serialNum: string;
  firmware: string;
  architectRevLevel: number | string;
};

export const Header = ({ serialNum, firmware, architectRevLevel }: propTypes) : ReactElement => {
  const classes = makeStyles(() => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      background: '#ecdef'
    },
    spanKey: {
      margin: '0 15px 0 25px',
      fontFamily: 'Lato',
      fontWeight: 'bold'
    },
    spanValue: {
      fontFamily: 'Lato',
      width: '120px',
      marginRight: '30px'
    }
  }))();

  return (
    <Card square className={classes.root}>
      <span className={classes.spanKey}>Quantum S/N: </span>
      <span className={classes.spanValue}>{serialNum}</span>
      <span className={classes.spanKey}>Firmware: </span>
      <span className={classes.spanValue}>{firmware}</span>
      <span className={classes.spanKey}>Architect Revision Level: </span>
      <span className={classes.spanValue}>{architectRevLevel}</span>
    </Card>
  );
};
