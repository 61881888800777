/* eslint-disable quotes */
import React, { ReactElement } from "react";
import {
  Select,
  FormControl
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f8f8f8",
    flexGrow: 1,
    width: "100%",
    height: "75px",
    maxHeight: "100px",
    alignContent: "baseline",
    alignItems: "center",
    display: "flex",
    paddingLeft: "15px"
  },
  formControl: {
    "margin": theme.spacing(1),
    "minWidth": 120,
    "textAlign": "center",
    "background": "white",
    "padding": "8px 16px",
    "border": "1px solid #e7e7e7",
    "borderRadius": "5px",
    "& .MuiInput-underline:before": {
      border: "none"
    }
  },
  filterRow: {},
  filterRowIcon: {
    position: "absolute",
    left: 0,
    marginRight: "5px"
  },
  filterRowInputOption: {
    textAlign: "center"
  }
}));

type pTypes = {
  selected: string;
  label?: string;
  testId?: string;
  children: React.ReactChild | React.ReactChild[];
  onChange: (event: React.ChangeEvent<{ name?: string | undefined; value: unknown;}>, child: React.ReactNode) => void;
}

const FilterRow = ({ selected, onChange, testId, children, label } : pTypes) : ReactElement => {
  const classes = useStyles();


  return (
    <FormControl className={classes.formControl}>
      <Select
        IconComponent={() => <FilterListIcon />}
        native
        value={selected}
        onChange={onChange}
        data-testid={testId || null}
        inputProps={{
          "name": "filter",
          "aria-label": label,
          "id": "filter-native-simple"
        }}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export { FilterRow };
