/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState, ReactElement } from 'react';
import { Button, FormControl, Grid, TextField, Backdrop, CircularProgress  } from '@material-ui/core';
import PageDataConfig from '../PageDataConfig';
import { useStyles } from '../sectionStyle';
import { useForm } from 'react-hook-form';
import { IRequestStatus } from '../../interfaces/util';
import ApiWrapper from '../../ApiWrapper';
import { useHistory } from 'react-router-dom';
import { TPropertyPostRequest, TPropertyFormData } from '../../interfaces/properties';
import Can from '../../Can';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { regionsSummary } from '../../endpoints/regions/regions';

const AddProperty = () : React.ReactElement => {
  const classes = useStyles();
  const [requestStatus, setRequestStatus] = useState<IRequestStatus>({ loading: false, success: false, error: false });
  const [modalDismissed, setModalDismissed] = useState(true);
  const history = useHistory();

  const dismissModal = () => {
    setModalDismissed(true);
  };

  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );
  const { register, handleSubmit, errors } = useForm<TPropertyFormData>();

  const compressPhone = (formattedPhone : string) : number => parseInt(formattedPhone.trim().replace(/[\(\)\- \+]/g, ''), 10);

  const onSubmit = async (data: TPropertyFormData): Promise<void> => {
    setModalDismissed(false);
    const formattedData : TPropertyPostRequest = {
      ...data,
      phone: compressPhone(data.phone)
    };

    try {
      setRequestStatus({ loading: true, success: false, error: false });
      await ApiWrapper({
        url: '/api/properties',
        method: 'POST',
        data: formattedData
      });
      setRequestStatus({ loading: false, success: true, error: false });

      // double anon function necessary since setState can take a lazy style func
      setModalAction( () => () => {
        history.push('/properties');
        return null;
      } );
    } catch (error) {
      setRequestStatus({ loading: false, success: false, error: true });
      console.error(error);
    }
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="debug-backdrop" onClick={modalAction} open={!modalDismissed} className={classes.backdrop}>
        {requestStatus.loading && (<CircularProgress color="inherit" />)}
        {requestStatus.error && (<span className={classes.loadingError}>Could not add property. Please refresh and try again.</span>)}
        {requestStatus.success && (<span className={classes.loadingError}>Property added successfully.</span>)}
      </Backdrop>
      <PageDataConfig title="Add Property" />
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
        <Can perform="regions" yes={() => (
          <FormControl className={classes.formControl}>
            <TextField
              id="region"
              label="Region"
              name="region"
              select
              SelectProps={{
                native: true
              }}
              inputRef={register({ required: true })}
              error={Boolean(errors.region)}
              helperText={errors.region ? 'Region must be selected' : ''}
              className={classes.formInput}
            >
              <FilterOptionsFromApi name="Region" optionsPath={regionsSummary} />
            </TextField>
          </FormControl>
        )} />
        <FormControl className={classes.formControl}>
          <TextField
            id="name"
            label="Name"
            name="name"
            inputRef={register({ required: true, minLength: 3 })}
            error={Boolean(errors.name)}
            helperText={errors.name ? 'Property name is required' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="address"
            label="Address"
            name="address"
            inputRef={register()}
            error={Boolean(errors.address)}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="city"
            label="City"
            name="city"
            inputRef={register()}
            error={Boolean(errors.city)}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="state"
            label="State"
            name="state"
            inputRef={register({ pattern: /^[A-Z]{2}$/ })}
            error={Boolean(errors.state)}
            helperText={errors.state ? 'Enter a valid state abbreviation (e.g. TN)' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="zip"
            label="ZIP"
            name="zip"
            inputRef={register({ pattern: /^[0-9]{5}(-[0-9]{4})?$/ })}
            error={Boolean(errors.zip)}
            helperText={errors.zip ? 'Enter a valid ZIP code (e.g. 55555, or 55555-5555)' : ''}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="contact"
            label="Contact"
            name="contact"
            inputRef={register()}
            error={Boolean(errors.contact)}
            className={classes.formInput}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            id="phone"
            label="Phone"
            name="phone"
            inputRef={register({ pattern: /^[0-9][\- ]?\(?[0-9]{3}\)?[\- ]?[0-9]{3}[\- ]?[0-9]{4}$/ })}
            error={Boolean(errors.phone)}
            helperText={errors.phone ? 'Enter a valid phone number (e.g. 1-555-555-5555)' : 'e.g. 1-555-555-5555'}
            className={classes.formInput}
          />
        </FormControl>
        <Button type="submit" className={classes.borderedButton}>Add</Button>
      </form>
    </Grid>
  );
};

export default AddProperty;
