import React, { ReactElement } from 'react';
import {
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PieChart, Pie, Cell } from 'recharts';
import Can from '../../Can';
import { Link } from 'react-router-dom';
import { EPermission } from '../../state-manipulation/reducers/user/types';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    height: '90%',
    padding: '5px'
  },
  subtleLink: {
    textDecoration: 'none'
  },
  cardDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardContent: {
    flex: '1 0 auto',
    marginLeft: '0px',
    paddingLeft: '0px'
  },
  pieChart: {
    marginLeft: '-20px',
    padding: '0px'
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    textEmphasis: 'bold',
    marginLeft: '2px'
  },
  contentValue: {
    fontWeight: 'bold',
    fontSize: 16,
    marginLeft: '10px',
    left: 0
  },
  perfRow: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row noWrap',
    flex: 1,
    padding: '2px',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  }
}));

const colorDictionary = {
  blue: '#0088FE',
  green: '#00C49F',
  yellow: '#FFBB28',
  red: '#FF8042',
  gray: '#ddd'
};

type TCardData = {
  name: string;
  color: 'blue' | 'green' | 'yellow' | 'red' | 'gray';
  value: number;
  link?: string;
  linkPermission?: keyof typeof EPermission;
}

const PerfCard = (props: { data: TCardData[]; title: string; }) : ReactElement => {
  const classes = useStyles();
  const { data, title } = props;


  return (
    <Card square className={classes.card}>
      <PieChart height={150} width={150} className={classes.pieChart}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          innerRadius="50%"
          outerRadius="60%"
          fill="#82ca9d"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colorDictionary[entry.color]} />
          ))}
        </Pie>
      </PieChart>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {title}
        </Typography>
        { data.map( (datum) => (
          <Can
            perform={ datum.linkPermission ? datum.linkPermission : 'alwaystrue'}
            yes={({ children } : {children:any}) => {
              if (datum.link) {
                return <Link className={classes.subtleLink} to={datum.link}>{children}</Link>;
              } else {
                return <>{children}</>;
              }
            }}
            no={({ children } : {children:any}) => <>{children}</> }
            propsToPass={{
              children: (
                <div className={classes.perfRow}>
                  <FontAwesomeIcon icon="circle" color={colorDictionary[datum.color]} />
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {datum.name}
                  </Typography>
                  <Typography className={classes.contentValue} color="textSecondary" gutterBottom>
                    {datum.value >= 0 && datum.value.toLocaleString()}
                  </Typography>
                </div>
              )
            }} />
        ))}
      </CardContent>
    </Card>
  );
};

export { PerfCard };
