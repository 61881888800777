import React, { Dispatch, useReducer, FunctionComponent, useContext, Reducer } from 'react';
import PropTypes from 'prop-types';
import pageDataReducer from '../reducers/page-data/pageDataReducer';

type SetValue = (value: any) => void;

type Action = {
  type: string;
  payload?: any;
};

interface PageDataContextInterface {
  state: any;
  dispatch: Dispatch<Action>;
}

export interface PageDataProps {
  pageName: string;
  siteName: string;
}

type PageDataProviderProps = {
  reducer: Reducer<PageDataProps, Action>;
  initState: PageDataProps;
  children: React.ReactNode;
};

interface InitContextProps {
  state: PageDataProps;
  dispatch: Dispatch<Action>;
}

const initialState = {
  pageName: '',
  siteName: ''
};

export const PageContext = React.createContext< [PageDataProps, Dispatch<Action>]>([initialState, () => true]);

const PageDataProvider: FunctionComponent = ({ children }) => {
  // const { children } = props;
  const pageData: [PageDataProps, Dispatch<Action>] = useReducer(pageDataReducer, initialState);
  return <PageContext.Provider value={pageData}>{children}</PageContext.Provider>;
};

const usePageDataState = () : [PageDataProps, Dispatch<Action>] => {
  const context = useContext(PageContext);
  if (context === undefined) {
    throw new Error('usePageDataState must be used within a PageDataProvider');
  }
  return context;
};

export { PageDataProvider, usePageDataState };

PageDataProvider.propTypes = {
  children: PropTypes.node
};

PageDataProvider.defaultProps = {
  children: React.Children
};
