/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, RefObject } from 'react';

const useOutsideClickCallback = (ref: RefObject<any>, onOutsideClick: () => void) : void => {
  useEffect(() => {

    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onOutsideClick.call(this);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

export default useOutsideClickCallback;
