import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    width: '100%',
    minWidth: '100%'
  },
  rootNoPadding: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    margin: '-1rem',
    width: 'calc(100% + 2rem)',
    height: 'calc(100vh - 60px)'
  },
  table: {
    width: '100%'
  },
  flexCell: {
    display: 'flex',
    alignItems: 'center'
  },
  noHoverRow: {
    '&:hover': {
      cursor: 'default'
    }
  },
  sectionSubmenu: {
    display: 'flex',
    marginLeft: 'auto',
    padding: '.25rem .75rem .25rem .25rem'
  },
  subtleActionItem: {
    'color': '#ddd',
    'cursor': 'pointer',
    '&:hover': {
      color: '#1B73FF'
    }
  },
  button: {
    'background': '#1B73FF',
    'color': '#fff',
    'maxWidth': '60%',
    'margin': '0 20px',
    'alignSelf': 'center',
    '&:hover': {
      color: '#1B73FF'
    }
  },
  borderedButton:  {
    border: '1px solid #ddd'
  },
  connectedIcon: {
    color: '#88bb55',
    padding: '0 1rem'
  },
  disabledButton: {
    background: '#ddd',
    color: '#777',
    cursor: 'not-allowed'
  },
  centeredCell: {
    display: 'flex',
    alignItems: 'center'
  },
  notConnectedIcon: {
    color: '#ccc',
    padding: '0 1rem'
  },
  filterRow: {
    padding: '0px',
    margin: '1rem 1rem 1rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    background: '#eee'
  },
  alertRow: {
    display: 'content',
    height: '10%'
  },
  relative: {
    position: 'relative'
  },
  backdrop: {
    zIndex: theme.zIndex.mobileStepper - 1,
    color: '#fff',
    top: '60px',
    cursor: 'default'
  },
  relativeBackdrop: {
    top: '0',
    position: 'absolute'
  },
  loadingError: {
    fontSize: '2rem',
    fontFamily: 'sans-serif',
    padding: '5rem',
    textAlign: 'center'
  },
  form: {
    width: '320px',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  formControl: {
    marginBottom: '1rem'
  },
  formInput: {

  },
  formLabel: {

  },
  cellValue: {

  },
  hiddenInput: {
    display: 'none'
  },
  cellPlaceholder: {
    opacity: 0.5
  },
  alertTableName: {
    textTransform: 'capitalize',
    paddingLeft: '5px'
  },
  infoWindowLink: {
    fontSize: '1.1rem',
    margin: '1rem',
    display: 'block'
  }
}),
{ name: 'HookGlobalStyles', index: 2 });
