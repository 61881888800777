import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Button, Backdrop, CircularProgress } from '@material-ui/core';
import { AxiosPromise } from 'axios';
import ApiWrapper from '../../../ApiWrapper';
import { reportExport } from '../../../endpoints/units';
import { IUnitReportExportResponse } from '../../../interfaces/units';
import { useStyles as useSectionStyles } from '../../sectionStyle';

type propTypes = {
  unitId: string;
};

interface ParamTypes {
  id: string
}

export const ReportExport = ({ unitId } : propTypes) : ReactElement => {

  const [exportNetworkRequest, setExportNetworkRequest] = useState({ status: 'init' });
  const [modalMessage, setModalMessage] = useState('');

  const [modalDismissed, setModalDismissed] = useState(true);
  const dismissModal = () => {
    setModalDismissed(true);
  };
  const [modalAction, setModalAction] = useState<()=>void>(() => dismissModal );


  const sectionClasses = useSectionStyles();
  const classes = makeStyles(() => ({
    card: {
      minHeight: 250,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Lato'
    },
    button: {
      'background': '#1B73FF',
      'color': '#fff',
      'margin': '0 20px',
      '&:hover': {
        color: '#1B73FF'
      }
    }
  }))();

  // Track if not mounted so state isnt updated on non-existent component
  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const sendRequest = async () => {
    try {
      setModalDismissed(false);
      setModalAction( () => () => {
        return null; //no action
      } );
      setExportNetworkRequest({ status: 'loading' });
      const promise: AxiosPromise<IUnitReportExportResponse> = ApiWrapper({
        url: reportExport(unitId),
        method: 'POST'
      });

      await promise;

      setModalAction( () => dismissModal);
      setModalMessage('Secured/Unsecured Location Report request received. Check your email after 2-3 minutes.');
      setExportNetworkRequest({ status: 'success' });


    } catch (e) {
      setExportNetworkRequest({ status: 'error' });
      setModalMessage(e.response.data.error);
      setModalAction( () => dismissModal);
      console.error('Error requesting report export', e);
    }
  };

  return (
    <Card className={classes.card}>
      <Backdrop data-testid="report-export-backdrop" onClick={modalAction} open={!modalDismissed} className={sectionClasses.backdrop}>
        { exportNetworkRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        { exportNetworkRequest.status !== 'loading' && (<span className={sectionClasses.loadingError}>{modalMessage ? modalMessage : ''}</span>)}
      </Backdrop>
      <Button className={classes.button} onClick={() => sendRequest()} >
        Request Secured/Unsecured Location Report
      </Button>
      <div>
        This can only be triggered once every 30 seconds. The system will email you a link to download a CSV file.
      </div>
    </Card>
  );
};
