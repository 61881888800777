import useUserData from './state-manipulation/hooks/useUserData';
import { TAccessRules, EPermission } from './state-manipulation/reducers/user/types';

export const check = (rules: TAccessRules, action: undefined | keyof typeof EPermission, data:string | undefined) : boolean => {

  if (!action) {
    return false;
  }

  if (action === 'alwaystrue') {
    return true;
  }

  if (action === 'alwaysfalse') {
    return false;
  }

  const staticPermissions = rules.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule provided for action
    return true;
  }

  const dynamicPermissions = rules.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export const checkAll = (rules: TAccessRules, actions: Array<keyof typeof EPermission>, data:string | undefined) : boolean => {
  let failure = false;
  actions.forEach((action) => {
    if (!check(rules, action, data)) {
      failure = true;
    }
  });
  return !failure;
};

export const checkAny = (rules: TAccessRules, actions: Array<keyof typeof EPermission>, data:string | undefined) : boolean => {
  let success = false;
  actions.forEach((action) => {
    if (check(rules, action, data)) {
      success = true;
    }
  });
  return success;
};

type TCanProps = {
  perform?: keyof typeof EPermission,
  performAll?: Array<keyof typeof EPermission>,
  performAny?: Array<keyof typeof EPermission>,
  data?: string,
  yes?: (propsToPass?: any) => React.ReactElement,
  no?: (propsToPass?: any) => React.ReactElement,
  propsToPass?: any
}

const useCanUser = ({ perform, performAll, performAny, data } : TCanProps) : boolean => {
  const userData = useUserData();
  if (!userData) {
    return false;
  }
  const accessRules = userData[0].accessRules;
  if (!accessRules) {
    return false;
  }

  if (performAll) {
    return checkAll(accessRules, performAll, data);
  } else if (performAny) {
    return checkAny(accessRules, performAny, data);
  } else {
    return check(accessRules, perform, data);
  }
};

const Can = ({
  perform,
  performAll,
  performAny,
  data,
  yes: unsafeYes,
  no: unsafeNo,
  propsToPass
} : TCanProps ) : React.ReactElement | null => {
  const userData = useUserData();
  const yes = unsafeYes ? unsafeYes : () => null;
  const no = unsafeNo ? unsafeNo : () => null;

  if (!userData) {
    return no(propsToPass);
  }
  const accessRules = userData[0].accessRules;
  if (!accessRules) {
    return no(propsToPass);
  }

  if (performAll) {
    return checkAll(accessRules, performAll, data) ? yes(propsToPass) : no(propsToPass);
  } else if (performAny) {
    return checkAny(accessRules, performAny, data) ? yes(propsToPass) : no(propsToPass);
  } else {
    return check(accessRules, perform, data) ? yes(propsToPass) : no(propsToPass);
  }
};

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;
export { useCanUser };
