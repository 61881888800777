import { useCanUser } from '../Can';

const useUnitLinkGenerator = () : (id:string) => string | null => {

  const canSeeDebugPage = useCanUser({
    perform: 'units:debug'
  });

  const canSeeDetailPage = useCanUser({
    perform: 'units'
  });

  if (canSeeDebugPage) {
    return (id:string) => `/unit/${id}/debug`;
  }

  if (canSeeDetailPage) {
    return (id:string) => `/unit/${id}`;
  }

  return () => null;
};

export default useUnitLinkGenerator;
