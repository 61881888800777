import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useStyles } from '../sectionStyle';
import { FilterRow } from '../FilterRow';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import EnhancedTable from '../Table/EnhancedTable';
import { Backdrop, Button, CircularProgress, Grid, TableCell, TableRow } from '@material-ui/core';
import { EditOutlined as EditIcon } from '@material-ui/icons';
import PageDataConfig from '../PageDataConfig';
import { ITableColumn } from '../../interfaces/tables';
import { getVehicles } from '../../endpoints/vehicles/vehicles';
import { propertiesSummary } from '../../endpoints/properties/properties';
import { IVehicle } from '../../interfaces/vehicles';
import { useHistory, useLocation } from 'react-router-dom';
import usePaginatedData from '../Table/usePaginatedData';
import usePaginationProps from '../Table/usePaginationProps';

import queryString from 'query-string';
import Can from '../../Can';

const Vehicles = () : ReactElement => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const initialPropertyFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.property && !Array.isArray(queryParams.property)) {
        return queryParams.property;
      }
    }
    return '';
  };

  const [propertyFilter, setPropertyFilter] = useState(initialPropertyFilter);

  const filters = useMemo( () => {
    const filtersObj : {[key:string]:string} = {};
    if (propertyFilter) {
      filtersObj.property = propertyFilter;
    }
    return filtersObj;
  }, [propertyFilter]);

  const paginationProps = usePaginationProps(getVehicles, { filters });
  const vehiclesRequest = usePaginatedData(paginationProps);

  const onClickHandler = useCallback((vehicle: IVehicle) => {
    history.push(`/units/?vehicle=${vehicle.id}`);
    return null;
  }, [history]);

  const columns : Array<ITableColumn> = useMemo( () => ([
    {
      id: 'name', label: 'Name', numeric: false, sortable: true, disablePadding: true
    },
    {
      id: 'units', label: '# Units', numeric: true, sortable: false, disablePadding: true
    },
    {
      id: 'routes', label: 'Routes', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'active', label: 'Status', numeric: false, sortable: false, disablePadding: true
    },
    {
      id: 'actions', label: 'Actions', numeric: false, sortable: false, disablePadding: true
    }
  ]), []);

  const vehicleRows = useMemo( () => {
    return vehiclesRequest.items.map( (vehicle) => (
      <TableRow
        hover
        tabIndex={-1}
        key={vehicle.id}
        onClick={ () => onClickHandler(vehicle) }
      >
        <TableCell size="small" align="center" key={'name'}>
          {vehicle.name}
        </TableCell>
        <TableCell size="small" align="center" key={'vehicles'}>
          {vehicle.units.length}
        </TableCell>
        <TableCell size="small" align="center" key={'street'}>
          {vehicle.routes}
        </TableCell>
        <TableCell size="small" align="center" key={'city'}>
          {vehicle.active === true ? 'Active' : 'Inactive' }
        </TableCell>
        <TableCell size="small" align="center" key={'actions'}>
          <Can perform="vehicles:edit"
            yes={() => (
              <EditIcon
                data-testid="edit-vehicle"
                aria-label="Edit Vehicles"
                className={classes.subtleActionItem}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(`/vehicle/${String(vehicle.id)}/edit`);
                }
                } />
            )} />
        </TableCell>
      </TableRow>
    ));
  }, [vehiclesRequest.items, onClickHandler, classes.subtleActionItem, history]);

  const handleFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setPropertyFilter(event.target.value);
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="debug-backdrop" open={Boolean(vehiclesRequest.status === 'loading' || vehiclesRequest.status === 'error')} className={classes.backdrop}>
        {vehiclesRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        {vehiclesRequest.status === 'error' && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Vehicles" />
      <Can
        perform="vehicles:add"
        yes={ () => (
          <div className={classes.sectionSubmenu}>
            <Button fullWidth={false} className={classes.borderedButton} type="button" onClick={() => {
              history.push('/vehicles/add');
            }}>
              Add Vehicle
            </Button>
          </div>
        )} />
      <Can perform="properties" yes={() => (
        <div className={classes.filterRow}>
          <FilterRow onChange={handleFilterChange} selected={propertyFilter}>
            <FilterOptionsFromApi name="Property" optionsPath={propertiesSummary} />
          </FilterRow>
        </div>)} />
      <div className={classes.table}>
        <EnhancedTable
          columns={columns}
          totalCount={vehiclesRequest.totalCount}
          {...paginationProps}
          hideControlColumn
        >
          {vehicleRows}
        </EnhancedTable>
      </div>
    </Grid>
  );
};

Vehicles.propTypes = {};

export { Vehicles };
