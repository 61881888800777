/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable indent */

import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { useStyles } from '../sectionStyle';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Grid, Select, TableCell, TableRow
} from '@material-ui/core';
import { Link as LinkIcon, LinkOff as LinkOffIcon, EditOutlined as EditIcon } from '@material-ui/icons';
import { FilterRow } from '../FilterRow';
import { FilterOptionsFromApi } from '../FilterOptionsFromApi';
import { getUnits } from '../../endpoints/units';
import { IUnit } from '../../interfaces/units';
import PageDataConfig from '../PageDataConfig';
import { useHistory, useLocation } from 'react-router-dom';
import usePaginatedData from '../Table/usePaginatedData';
import usePaginationProps from '../Table/usePaginationProps';
import Can from '../../Can';
import { ITableColumn } from '../../interfaces/tables';
import queryString from 'query-string';
import EnhancedTable from '../Table/EnhancedTable';
import { vehiclesSummary } from '../../endpoints/vehicles/vehicles';
import useUnitLinkGenerator from '../useUnitLinkGenerator';
import { regionsSummary } from '../../endpoints/regions/regions';

const Units = () : ReactElement => {
  const classes = useStyles();
  const location = useLocation();
  const unitLinkGenerator = useUnitLinkGenerator();
  const history = useHistory();

  const initialVehicleFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.vehicle && !Array.isArray(queryParams.vehicle)) {
        return queryParams.vehicle;
      }
    }
    return '';
  };
  const [vehicleFilter, setVehicleFilter] = useState(initialVehicleFilter);

  const initialConnectedFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.connected && !Array.isArray(queryParams.connected)) {
        return queryParams.connected;
      }
    }
    return '';
  };

  const [connectedFilter, setConnectedFilter] = useState(initialConnectedFilter);

  const initialRegionFilter = () => {
    if (location && location.search) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.region && !Array.isArray(queryParams.region)) {
        return queryParams.region;
      }
    }
    return '';
  };

  const [regionFilter, setRegionFilter] = useState(initialRegionFilter);

  const vehicleOptions = useMemo( () => ({ filters: { region: regionFilter } }), [regionFilter] );

  const filters = useMemo( () => {
    const filtersObj : {[key:string]:string} = {};
    if (vehicleFilter) {
      filtersObj.vehicle = vehicleFilter;
    }
    if ( location.search ) {
      const queryParams = queryString.parse(location.search);
      if (queryParams.id && !Array.isArray(queryParams.id)) {
        filtersObj.id = queryParams.id;
      }
    }
    if (connectedFilter) {
      filtersObj.connected = connectedFilter;
    }
    if (regionFilter) {
      filtersObj.region = regionFilter;
    }
    return filtersObj;
  }, [vehicleFilter, regionFilter, connectedFilter, location]);

  const paginationProps = usePaginationProps(getUnits, { sortColumn: 'name', filters });
  const unitsRequest = usePaginatedData(paginationProps);

  const columns : Array<ITableColumn> = useMemo( () => ([
    { id: 'id', sortable: true, numeric: true, disablePadding: true, label: 'Serial Number' },
    { id: 'machineSN', sortable: false, numeric: false, disablePadding: false, label: 'Machine S/N' },
    { id: 'property', sortable: false, numeric: false, disablePadding: false, label: 'Property' },
    { id: 'city', sortable: false, numeric: false, disablePadding: false, label: 'City' },
    { id: 'vehicle', sortable: false, numeric: false, disablePadding: false, label: 'Vehicle' },
    { id: 'zip', sortable: false, numeric: false, disablePadding: false, label: 'Zip Code' },
    { id: 'actions', sortable: false, numeric: false, disablePadding: false, label: 'Actions' }

  ]), []);

  const onClickHandler = useCallback((unit: IUnit) => {
    const link = unitLinkGenerator(unit.id);
    if (link) {
      history.push(link);
    }
    return null;
  }, [history, unitLinkGenerator]);

  const unitRows = useMemo( () => {
    return unitsRequest.items.map( (unit:IUnit) => {
      return (<TableRow
        hover
        tabIndex={-1}
        key={unit.id}
        onClick={ () => onClickHandler(unit) }
      >
        <TableCell size="small" align="center" key={'name'}>
          { unit.connected ? <LinkIcon aria-label="connected" className={classes.connectedIcon} /> : <LinkOffIcon aria-label="not connected" className={classes.notConnectedIcon} /> }
          {unit.name}
        </TableCell>
        <TableCell size="small" align="center" key="machineSN">
          {unit.machineSN}
        </TableCell>
        <TableCell size="small" align="center" key="property">
          {unit.property}
        </TableCell>
        <TableCell size="small" align="center" key="city">
          {unit.city}
        </TableCell>
        <TableCell size="small" align="center" valign="middle" key="vehicle" >
          <div className={classes.flexCell}>
            {unit.vehicle}
          </div>
        </TableCell>
        <TableCell size="small" align="center" key={'street'}>
          {unit.zip}
        </TableCell>
        <TableCell size="small" align="center" key={'actions'}>
          <Can perform="units:edit"
            yes={() => (
              <EditIcon
                data-testid="edit-property"
                aria-label="Edit Unit"
                className={classes.subtleActionItem}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  history.push(`/unit/${String(unit.id)}/edit`);
                }
                } />
            )} />
        </TableCell>
      </TableRow>);
    });
  }, [classes.connectedIcon, classes.flexCell, classes.notConnectedIcon, classes.subtleActionItem, history, onClickHandler, unitsRequest]);

  const handleFilterChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setVehicleFilter(event.target.value);
  };

  const handleConnectedChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setConnectedFilter(event.target.value);
  };

  const handleRegionChange = (event: React.ChangeEvent<{name?: string | undefined; value: unknown;}>) => {
    setVehicleFilter('');
    setRegionFilter(event.target.value);
  };

  return (
    <Grid container spacing={0} className={classes.root}>
      <Backdrop data-testid="debug-backdrop" open={Boolean(unitsRequest.status === 'loading' || unitsRequest.status === 'error')} className={classes.backdrop}>
        {unitsRequest.status === 'loading' && (<CircularProgress color="inherit" />)}
        {unitsRequest.status === 'error' && (<span className={classes.loadingError}>Could not load data. Please refresh and try again.</span>)}
      </Backdrop>
      <PageDataConfig title="Units" />
      <div className={classes.filterRow}>
        <Can perform="regions" yes={() => (
          <FilterRow onChange={handleRegionChange} selected={regionFilter} testId="region" >
            <FilterOptionsFromApi name="Region" optionsPath={regionsSummary}/>
            {/* <option key="999" value="999">
              Region 999
            </option> */}
          </FilterRow>
        )} />
        <Can perform="vehicles" yes={() => (
          <FilterRow onChange={handleFilterChange} selected={vehicleFilter} testId="vehicle">
            <FilterOptionsFromApi name="Vehicle" optionsPath={vehiclesSummary} opts={vehicleOptions}/>
          </FilterRow>
        )} />
        <FilterRow label="Status" onChange={handleConnectedChange} selected={connectedFilter} >
            <option value="">Connection Status</option>
            <option key="connected" value="connected">
              Connected
            </option>
            <option key="disconnected" value="disconnected">
              Disconnected
            </option>
        </FilterRow>
      </div>
      <div className={classes.table}>
        <EnhancedTable
          columns={columns}
          totalCount={unitsRequest.totalCount}
          {...paginationProps}
          hideControlColumn
        >
          {unitRows}
        </EnhancedTable>
      </div>
    </Grid>
  );
};

Units.propTypes = {};

export { Units };
