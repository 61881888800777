import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import StandardLayout from './layouts/StandardLayout';
import AuthWrapper from './state-manipulation/providers/AuthWrapper';
import { UserDataProvider } from './state-manipulation/providers/UserDataProvider';
import { PageDataProvider } from './state-manipulation/providers/PageDataProvider';
import { QstraintTheme } from './material-theme-styles/qstraint-theme';

import './index.css';

// adding all fas icons
library.add(fas);

const loadServiceWorkerIfMocking = () => {
  if (process.env.REACT_APP_MOCK_API) {
    const loadWorker = (): Promise<typeof import('./mocks/browser')> => import('./mocks/browser');
    loadWorker().then((r) => r.worker.start()).catch( () => { return; });
  }
};

loadServiceWorkerIfMocking();

ReactDOM.render(
  <Router>
    <AuthWrapper>
      <PageDataProvider>
        <UserDataProvider>
          <Switch>
            <Route path={'/'}>
              <ThemeProvider theme={QstraintTheme}>
                <StandardLayout />
              </ThemeProvider>
            </Route>
          </Switch>
        </UserDataProvider>
      </PageDataProvider>
    </AuthWrapper>
  </Router>,
  document.querySelector('#root')
);
