import React, { useState, ReactElement } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { AxiosPromise } from 'axios';
import { useParams } from 'react-router';
import ApiWrapper from '../../../ApiWrapper';
import { unitCommand } from '../../../endpoints/units';
import { IUnitCommandResponse } from '../../../interfaces/units';

type ThresholdFormErrors = {
  threshold: boolean;
  value: boolean;
};

type propTypes = {
  thresholds: Record<string, any>;
  refreshDataIfDefaultRange: () => void;
};

type ParamsType = {
  id: string;
}

export const ThresholdCommand = ({ thresholds, refreshDataIfDefaultRange }: propTypes) : ReactElement => {
  const { id } = useParams<ParamsType>();
  const [thresholdValue, setThresholdValue] = useState('');
  const [currentThreshold, setCurrentThreshold] = useState('');
  const [formErrors, setFormErrors] = useState<ThresholdFormErrors>({ threshold: false, value: false });
  const classes = makeStyles((theme) => ({
    root: {
      display: 'inherit',
      flexDirection: 'column',
      width: '100%'
    },
    formControl: {
      padding: '10px',
      margin: theme.spacing(1)
    },
    formLabel: {
      textAlign: 'left',
      fontWeight: 'bold',
      paddingBottom: '20px',
      color: '#222222'
    },
    button: {
      'background': '#1B73FF',
      'color': '#fff',
      'maxWidth': '60%',
      'margin': '0 20px',
      'alignSelf': 'center',
      '&:hover': {
        color: '#1B73FF'
      }
    },
    title: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      minWidth: '154px',
      padding: '5px',
      fontSize: '10pt'
    }
  }))();

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const errors: ThresholdFormErrors = {
      threshold: currentThreshold.length === 0,
      value: !/^\d+$/.test(thresholdValue)
    };

    if (errors.threshold || errors.value) {
      setFormErrors(errors);
    } else {
      try {
        const promise: AxiosPromise<IUnitCommandResponse> = ApiWrapper({
          url: unitCommand(id),
          method: 'POST',
          data: { commandType: 'threshold', thresholdValue, threshold: currentThreshold }
        });

        await promise;
        refreshDataIfDefaultRange();
        setThresholdValue('');
      } catch (_e) {
        console.error(`Error sending command to update ${currentThreshold} for Device ${id}`);
      }
    }
  };

  return (
    <form className={classes.root} onSubmit={onFormSubmit}>
      <FormControl className={classes.formControl} error={formErrors.threshold}>
        <FormLabel className={classes.formLabel}>Threshold Type</FormLabel>
        <Select
          variant="outlined"
          id="thresholdName"
          displayEmpty
          value={currentThreshold}
          onChange={(e: React.ChangeEvent<any>) => setCurrentThreshold(e.target.value)}
        >
          {Object.keys(thresholds).map((t) => (
            <MenuItem key={t} value={t}>
              {t}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl} error={formErrors.value}>
        <TextField
          id="thresholdValue"
          variant="outlined"
          type="number"
          onChange={(e: React.ChangeEvent<any>) => setThresholdValue(e.target.value)}
          placeholder={
            thresholds[currentThreshold] || thresholds[currentThreshold] === 0
              ? thresholds[currentThreshold]
              : 'Value'
          }
          value={thresholdValue}
        />
        <FormHelperText>{formErrors.value ? 'Cannot Be Blank or Contain Non-Numerical Characters' : ''}</FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Button type="submit" className={classes.button}>
          <span className={classes.title}>Update Threshold</span>
        </Button>
      </FormControl>
    </form>
  );
};
