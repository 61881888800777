import React, { ReactElement } from 'react';
import JSONPretty from 'react-json-pretty';
import { makeStyles } from '@material-ui/core/styles';
import 'react-json-pretty/themes/monikai.css';

type propTypes = { data: any };

export const RawDeviceInfo = ({ data } : propTypes) : ReactElement => {
  const classes = makeStyles(() => ({
    root: {
      margin: '20px',
      background: '#eee'
    },
    code: {
      '& pre': {
        padding: 10,
        maxHeight: 350,
        overflowY: 'scroll'
      }
    }
  }))();

  return (
    <div className={classes.root}>
      <code>
        <JSONPretty id="json-pretty" data={data} className={classes.code} />
      </code>
    </div>
  );
};
