import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Typography } from '@material-ui/core';
import { IAlertsTotalsRowProps } from '../../interfaces/alerts';
import AlertIcon from './AlertIcon';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '140px',
    background: 'white',
    padding: '25px',
    margin: '0px'
  },
  alertRow: {},
  alertRowItem: {
    background: 'inherit',
    display: 'flex',
    flexFlow: 'row nowrap',
    height: '100%',
    alignContent: 'baseline',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: '5px',
    marginRight: '5px'
  },
  alertRowItemLabel: {
    fontWeight: 200,
    color: '#272727',
    marginRight: '5px'
  },
  alertRowItemValue: {
    fontWeight: 700,
    fontSize: '125%',
    color: '#272727'
  },
  alertRowContent: {}
});

const AlertsTotalsRow = ({ totalCount, totalWarning, totalCritical }: IAlertsTotalsRowProps) : ReactElement => {
  const classes = useStyles();

  const FormRow = (): ReactElement => {
    return (
      <>
        <Grid item xs={2} spacing={3} className={classes.alertRowItem}>
          <AlertIcon level="info" size="2x" />
          <Typography aria-label="Total Alerts" role="term" className={classes.alertRowItemLabel} color="textSecondary" gutterBottom>
            Total Alerts
          </Typography>
          <Typography role="definition" className={classes.alertRowItemValue} color="textSecondary" gutterBottom>
            {totalCount}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2} spacing={3} className={classes.alertRowItem}>
          <AlertIcon level="warning" size="2x" />
          <Typography aria-label="Warning" role="term" className={classes.alertRowItemLabel} color="textSecondary" gutterBottom>
            Warning
          </Typography>
          <Typography role="definition" className={classes.alertRowItemValue} color="textSecondary" gutterBottom>
            {totalWarning}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={2} spacing={3} className={classes.alertRowItem}>
          <AlertIcon level="critical" size="2x" />
          <Typography aria-label="Service Required" role="term" className={classes.alertRowItemLabel} color="textSecondary" gutterBottom>
            Service Required
          </Typography>
          <Typography role="definition" className={classes.alertRowItemValue} color="textSecondary" gutterBottom>
            {totalCritical}
          </Typography>
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item className={classes.alertRowItem}>
          <div>
            <div>
              <Typography aria-label="FIELD SERVICE CONTACT" role="term" align="center" color="textPrimary" gutterBottom>
                FIELD SERVICE CONTACT
              </Typography>
            </div>
            <div>
              <Typography aria-label="954-290-0201" role="definition" align="center" color="textPrimary" gutterBottom>
                954-290-0201
              </Typography>
            </div>
            <div>
              <Typography aria-label="service@qstraint.com" role="definition" align="center" color="textSecondary" gutterBottom>
                <a href="mailto:service@qstraint.com">service@qstraint.com</a>
              </Typography>
            </div>
          </div>
        </Grid>
      </>
    );
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <FormRow />
    </Grid>
  );
};

export { AlertsTotalsRow };
