import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles( () => {
  return {
    messagePane: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: 300,
      fontFamily: "'Lato', sans-serif",
      color: '#777'
    },
    title: {
      fontSize: '300%'
    },
    message: {
      fontSize: '150%'
    },
    link: {
      fontSize: '125%'
    }
  };
});

const MessagePane = ({ title, message, linkPath, linkText } : {title:string, message?:string, linkPath?:string, linkText?:string}) : React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.messagePane} >
      <h1 className={classes.title}>{title}</h1>
      <div>
        {message && (<div className={classes.message}>{message}</div>) }
        {linkPath && linkText && (<Link to={linkPath}>{linkText}</Link>) }
      </div>
    </div>
  );
};

export default MessagePane;
