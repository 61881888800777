/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, {
  AxiosResponse,
  AxiosRequestConfig
} from 'axios';
import { getTarget } from './targets';

export default async function ApiWrapper(
  {
    url, cancelToken, method, data, timeout, params
  }: AxiosRequestConfig) : Promise<AxiosResponse<any>> {

  const token = sessionStorage.getItem('JWT_TOKEN');

  const orgUrl = getTarget().url;

  const url2 = `${orgUrl}${url ? url : ''}`;

  const config: AxiosRequestConfig = {
    headers: {
      'Authorization': token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    cancelToken,
    url: url2,
    method,
    timeout,
    data,
    params
  };

  const response = await axios(config);
  if (response) {
    return response;
  }

  throw new Error('Invalid network response');
}

ApiWrapper.defaultProps = {
  timeout: 120000,
  isSaml: false
};
